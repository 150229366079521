import React from "react";
import styles from "./HomeCard.module.css";
import { Link } from "react-router-dom";
import { IconFont } from "../../../../services/home";

const HomeCard = ({ title, icon, description, setCurrentCategory }) => {
  return (
    <Link to="/templates">
      <div
        className={styles.homeCard}
        onClick={() => {
          setCurrentCategory(title);
          localStorage.setItem("current_category", title);
        }}
      >
        <div className={styles.homeCardCircle}>
          <IconFont type={`icon-${icon}`} style={{ fontSize: "3rem" }} />
        </div>
        <div className={styles.homeCardDescription}>
          <div className={styles.homeCardDescriptionHeading}>
            <h1>{title}</h1>
          </div>
          <div className={styles.homeCardDescriptionText}>
            <p>{description}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};

/*<img
  src={process.env.PUBLIC_URL + "/resources/2-3.png"}
  alt="flash player logo"
/>;*/

export default HomeCard;
