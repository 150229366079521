export const notificationsElement = (editor, res) => {
  editor.DomComponents.addType("notification", {
    // Special function which loops througth nodes in canvas and if condition is met returns
    // object which indicates that node is of that specific type.
    isComponent: (el) => {
      if (el.tagName === "DIV") {
        if (el.className && el.className.includes("notification")) {
          const result = {
            type: "notification", //component type, eg. `text`, `image`, `video`, etc.
          };
          return result;
        }
      }
    },
    model: {
      defaults: {
        tagName: "div", //HTML tag of the component, eg. `span`. Default: `div`
        draggable: "[data-gjs-type=wrapper]", // You can also specify a query string to indentify elements,
        // eg. `'.some-class[title=Hello], [data-gjs-type=column]'` means you can drag the component only inside elements
        // containing `some-class` class and `Hello` title, and `column` components.
        droppable: false, // Indicates if it's possible to drop other components inside.
        //components: flashPlayerWindows,
        preset: null, // Custom property
        customPosition: null, // Custom property
        animation: false,
        animationDelay: 0,
        animationDuration: 1,
        // Component's traits.
        traits: [
          {
            type: "select-preset", // Type of option (trait). // If you don't specify the type, the `text` is the default one
            name: "preset", // The name of the attribute/property which this trait refers to.
            changeProp: 1, // Ability to switch to chaning the component property instead of attribute.
            label: "More notification templates: ", // The label you will see in front of trait box
            options: res,
          },
          {
            type: "custom-positioning",
            name: "customPosition",
            changeProp: 1,
            label: "Select position",
          },
          {
            type: "animations-selector",
            name: "animation",
            changeProp: 1,
            label: "Select animation",
          },
          {
            type: "number",
            label: "Animation delay(in seconds): ",
            name: "animationDelay",
            placeholder: "0-5",
            changeProp: 1,
            min: 0, // Minimum number value
            max: 5, // Maximum number value
            step: 1, // Number of steps
          },
          {
            type: "number",
            label: "Animation duration(in seconds): ",
            name: "animationDuration",
            placeholder: "0-5",
            changeProp: 1,
            min: 0, // Minimum number value
            max: 5, // Maximum number value
            step: 0.1, // Number of steps
          },
        ],
        //style: {}, // Define default styling for component (applies always)
      },
    },

    view: {
      tagName: "div", // By default it has to be same as model's tagName
      // Add component specific event listeners
      // If you need some inner element listener  => 'dblclick .inner-el':
      events: {
        click: "clickOnNotification",
      },
      //clickOnNotification(e) {}, // Dummy event handler
      init() {
        // Call specific functions when model properties change.
        this.listenTo(this.model, "change:preset", this.onPresetChange);
        this.listenTo(
          this.model,
          "change:customPosition",
          this.onPositionChange
        );
        this.listenTo(
          this.model,
          "change:animation change:animationDelay change:animationDuration",
          this.onAnimationChange
        );
      },

      //Event handler that listens to "preset" change and swaps whole notification with different one.
      onPresetChange(model) {
        if (model.get("preset")) {
          const filtered = res.filter(
            (el) => el.id.toString() === model.get("preset")
          );
          if (filtered[0].content.indexOf("gj-ad-slider-content") !== -1)
            model.setAttributes({
              class: "gj-ad-slider-wrapper notification",
            });
          else if (
            filtered[0].content.indexOf("notif-1077-flash-notification") !== -1
          )
            model.setAttributes({
              class: "notif-1077-flash-notification",
            });
          else
            model.setAttributes({
              class: "flash-notification",
            });
          model.components(filtered[0].content);
        }
      },
      //Event handler that listens to "customPosition" change and moves the notification accordingly in canvas.
      onPositionChange(model) {
        if (model.get("customPosition")) {
          let previousStyle = model.getStyle();
          previousStyle = { ...previousStyle, position: "absolute" };
          switch (model.get("customPosition")) {
            case "top-right":
              model.setStyle({
                ...previousStyle,
                top: "5%",
                right: "0",
                bottom: "auto",
                left: "auto",
              });
              break;
            case "top-left":
              model.setStyle({
                ...previousStyle,
                top: "5%",
                right: "auto",
                bottom: "auto",
                left: "0",
              });
              break;
            case "bottom-left":
              model.setStyle({
                ...previousStyle,
                top: "auto",
                right: "auto",
                bottom: "5%",
                left: "0",
              });
              break;
            case "bottom-right":
              model.setStyle({
                ...previousStyle,
                top: "auto",
                right: "0",
                bottom: "5%",
                left: "auto",
              });
              break;
            default:
          }
        }
      },

      onAnimationChange(model) {
        let previousStyle = model.getStyle();
        let currentAnimation = model.get("animation");
        let currentDelay = model.get("animationDelay");
        let currentDuration = model.get("animationDuration");
        if (currentAnimation) {
          model.setStyle({
            ...previousStyle,
            "animation-timing-function": "ease-in",
            "animation-fill-mode": "forwards",
          });
          switch (currentAnimation) {
            case "fade-in":
              model.setStyle({
                ...previousStyle,
                opacity: "0",
                "animation-duration": `${currentDuration}s`,
                "animation-name": "fade-in",
                "animation-delay": `${currentDelay}s`,
              });
              break;
            case "pulse":
              model.setStyle({
                ...previousStyle,
                opacity: "1",
                "animation-duration": `${currentDuration}s`,
                "animation-name": "pulse",
                "animation-delay": `${currentDelay}s`,
              });
              break;
            case "popright":
              model.setStyle({
                ...previousStyle,
                opacity: "0",
                "animation-duration": `${currentDuration}s`,
                "animation-name": "popright",
                "animation-delay": `${currentDelay}s`,
              });
              if (!editor.getCss().includes("overflow-x"))
                editor.setStyle(
                  `body { overflow-x: hidden; }${editor.getCss({
                    avoidProtected: true,
                  })}`
                );
              break;
            case "slideInDown":
              model.setStyle({
                ...previousStyle,
                opacity: "1",
                "animation-duration": `${currentDuration}s`,
                "animation-name": "slideInDown",
                "animation-delay": `${currentDelay}s`,
              });
              break;
            case "scaleIn":
              model.setStyle({
                ...previousStyle,
                opacity: "0",
                "animation-duration": `${currentDuration}s`,
                "animation-name": "scaleIn",
                "animation-delay": `${currentDelay}s`,
              });
              break;
            default:
          }
        } else {
          model.setStyle({
            ...previousStyle,
            opacity: "1",
            "animation-name": "none",
          });
        }
      },

      //If event listeners cleanup is needed
      //removed() { document.removeEventListener("click", this.onDocClick); },

      //Function that is called everytime element is added in the canvas or something changes in it.
      onRender({ model, editor }) {
        const checkForCss = editor.CssComposer.getRule(`#${model.ccid}`);
        if (checkForCss) {
          const modelStyle = checkForCss.attributes.style;
          if (modelStyle["animation-name"]) {
            model.set("animation", modelStyle["animation-name"]);
          }
          if (modelStyle["animation-delay"]) {
            //model.animationDelay = modelStyle["animation-delay"];
            const aDel = modelStyle["animation-delay"];
            if (aDel.length > 1)
              model.set(
                "animationDelay",
                parseFloat(aDel.substring(0, aDel.length - 1))
              );
            else model.set("animationDelay", 0);
          }
          if (modelStyle["animation-duration"]) {
            //model.animationDuration = modelStyle["animation-duration"];
            const aDur = modelStyle["animation-duration"];
            if (aDur.length > 1)
              model.set(
                "animationDuration",
                parseFloat(aDur.substring(0, aDur.length - 1))
              );
            else model.set("animationDuration", 0);
          }
        }
      },
    },
  });
};
