import { getQueryString } from "../utils/query";
import { callApi } from "./api";

class TemplatesService {
  async getTemplateCategories() {
    return await callApi({ url: "/lp-types" });
  }
  async getTemplatesByCategory(category) {
    const query = getQueryString({ category });
    return await callApi({ url: `/templates?${query}` });
  }
  async getTemplatesFiltered(category, title, is_adult) {
    const query = getQueryString({ category, title, is_adult });
    return await callApi({ url: `/templates?${query}` });
  }
  async deleteLanding(id) {
    return await callApi({ url: `/landing-pages/${id}`, method: "DELETE" });
  }
  async getLandingByTitle(value, father_id) {
    return await callApi({
      url: `/landing-pages?title=${value}&father_template=${father_id}`,
    });
  }
  async getLandingById(id, father_id) {
    return await callApi({
      url: `/landing-pages?id=${id}&father_template=${localStorage.getItem(
        "father_template"
      )}`,
    });
  }
}

export const templatesService = new TemplatesService();
