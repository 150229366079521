import { flashPlayerWindows } from "../grapesElements/presets/notification/flashPlayerWindows";
import { flashPlayerMacBig } from "../grapesElements/presets/modal/flashPlayerMacBig";
import { initialForm } from "../grapesElements/presets/form/initialForm";
import { formJS } from "../grapesElements/presets/form/formJS";
import { flashPlug } from "../grapesElements/presets/plug/flash-plug";
import { blopSound } from "../grapesElements/presets/audio/blop";
import { hiddenInputs } from "../grapesElements/presets/form/hiddenInputs";

export const blockManagerSettings = {
  appendTo: ".content-container",
  blocks: [
    {
      id: "text",
      category: "Main",
      label: `<div class="block-inner"><img src=${
        process.env.PUBLIC_URL + "/resources/text.svg"
      } alt=""/><span>Text</span></div>`,
      content:
        '<div data-gjs-type="text" style="text-align: center;">Insert your text here</div>',
    },
    {
      id: "image",
      category: "Main",
      label: `<div class="block-inner" ><img src=${
        process.env.PUBLIC_URL + "/resources/image.svg"
      } alt=""/><span>Image</span></div>`,
      select: true, // Select the component once it's dropped
      // You can pass components as a JSON instead of a simple HTML string,
      // in this case we also use a defined component type `image`
      content: { type: "image" },
      // This triggers `active` event on dropped components and the `image`
      // reacts by opening the AssetManager
      activate: true,
    },
    {
      id: "button", // id is mandatory
      category: "Main",
      label: `<div class="block-inner"><img src=${
        process.env.PUBLIC_URL + "/resources/button.svg"
      } alt=""/><span>Button</span></div>`, // You can use HTML/SVG inside labels
      content: `<button class="gjs-button" href="{{FILE_URL}}" style="background-color: #ED3F2A; 
      cursor: pointer; padding: 16px; color: white; border-radius: 5px; width: 170px; text-align: center;"><span>SIMPLE BUTTON</span></button>
      <style>`,
    },
    {
      id: "block", // id is mandatory
      category: "Main",
      label: `<div class="block-inner"><img src=${
        process.env.PUBLIC_URL + "/resources/box.svg"
      } alt=""/><span>Box</span></div>`, // You can use HTML/SVG inside labels
      content: `<div class="simple-box" style="height: 40px; background-color: c3c3c3; width: 200px;"></div>`,
    },
    // {
    //   id: "snippet", // id is mandatory
    //   attributes: { draggable: "false" },
    //   label: `<div class="block-inner disabled"><img src=${
    //     process.env.PUBLIC_URL + "/resources/snippet.svg"
    //   } alt=""/><span>Snippet</span>
    // </div>`,
    //   content: ``,
    // },
    {
      id: "notification", // id is mandatory
      category: "Main",
      label: `<div class="block-inner"><img src=${
        process.env.PUBLIC_URL + "/resources/notification.svg"
      } alt=""/><span>Notification</span></div>`,
      content: `<div class="flash-notification" data-gjs-type="notification">${flashPlayerWindows}</div>`,
    },
    {
      id: "form", // id is mandatory
      category: "Main",
      label: `<div class="block-inner"><img src=${
        process.env.PUBLIC_URL + "/resources/form.svg"
      } alt=""/><span>Form</span></div>`,
      content: `<div class="cod-form" data-gjs-type="cod-form">${hiddenInputs}${formJS}${initialForm}</div>`,
    },
    {
      id: "image-with-text", // id is mandatory
      category: "Main",
      label: `<div class="block-inner"><img src=${
        process.env.PUBLIC_URL + "/resources/imagewithtext.svg"
      } alt=""/><span>Image with Text</span></div>`,
      content: `<div
      class="image-with-text-box"
      style="
        display: flex;
        flex-direction: row;
        height: 200px;
        padding: 10px;
        width: 600px;
      "
    >
      <div class="img-box" style="padding: 10px">
        <img src="" alt="" style="height: 100%; width: auto" />
      </div>
      <div class="text-box" style="margin-left: 10px">
        <p>
          Paragraph 1 - Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Quis voluptates ex aliquid, placeat sunt quidem harum ipsa magnam esse sed
          id officia quisquam aliquam quasi, beatae labore nihil earum fuga!
        </p>
        <p>
          Paragraph 2 - Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Quis voluptates ex aliquid, placeat sunt quidem harum ipsa magnam esse sed
        </p>
      </div>
    </div>`,
    },
    {
      id: "headline", // id is mandatory
      category: "Main",
      label: `<div class="block-inner"><img src=${
        process.env.PUBLIC_URL + "/resources/headline.svg"
      } alt=""/><span>Headline</span></div>`,
      content: `<h1 style="color: #333; text-align: center; font-family: sans-serif; font-size: 3rem;">This is a Headline</h1>`,
    },
    {
      id: "space-divider", // id is mandatory
      category: "Main",
      label: `<div class="block-inner"><img src=${
        process.env.PUBLIC_URL + "/resources/spacedivider.svg"
      } alt=""/><span>Space Divider</span></div>`,
      content: `<div style="width: 100%; height: 100px;"></div>`,
    },
    {
      id: "timer", // id is mandatory
      category: "Main",
      label: `<div class="block-inner"><img src=${
        process.env.PUBLIC_URL + "/resources/stopwatch.svg"
      } alt=""/><span>Timer</span></div>`,
      content: {
        type: "timer",
        style: { "font-size": "2rem", "text-align": "center" },
      },
    },
    {
      id: "modal-window", // id is mandatory
      category: "Main",
      label: `<div class="block-inner"><img src=${
        process.env.PUBLIC_URL + "/resources/modalwindow.svg"
      } alt=""/><span>Modal Window</span></div>`,
      content: `<div class="gjs-overlay" style="position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);"></div>${flashPlayerMacBig}`,
    },
    {
      id: "header-plug", // id is mandatory
      category: "Main",
      label: `<div class="block-inner"><img src=${
        process.env.PUBLIC_URL + "/resources/header-plug.svg"
      } alt=""/><span>Plug</span></div>`,
      content: `<div class="flash-plug-default">${flashPlug}</div>`,
    },
    {
      id: "audio", // id is mandatory
      category: "Main",
      label: `<div class="block-inner"><img src=${
        process.env.PUBLIC_URL + "/resources/audio.svg"
      } alt=""/><span>Audio</span></div>`,
      content: `<audio src=${blopSound}>Test</audio>`,
    },
    {
      category: "Main",
      label: `<div class="block-inner"><img src=${
        process.env.PUBLIC_URL + "/resources/video.svg"
      } alt=""/><span>Video</span></div>`,
      content: {
        type: "video",
        src: "",
        style: {
          height: "350px",
          width: "615px",
        },
      },
    },
    {
      category: "Main",
      id: "date", // id is mandatory
      label: `<div class="block-inner"><img src=${
        process.env.PUBLIC_URL + "/resources/date.svg"
      } alt=""/><span>Date</span></div>`,
      content: `<div class="gjs-date" style="display: inline-block" dateformat="DD/MM/YYYY"></div>`,
    },
  ],
};
