export const panelsSettings = {
  defaults: [
    {
      id: "sider",
      el: ".panel__right",
      resizable: {
        maxDim: 500,
        minDim: 200,
        tc: 0, // Top handler
        cl: 1, // Left handler
        cr: 0, // Right handler
        bc: 0, // Bottom handler
        // Being a flex child we need to change `flex-basis` property
        // instead of the `width` (default)
        keyWidth: "flex-basis",
      },
    },
    {
      id: "small-panel",
      el: ".small-panel",
      buttons: [
        {
          id: "head-settings",
          //className: "fa fa-hashtag",
          style: { "margin-right": "50px" },
          label: `<span style="font-weight: 600; font-family: 'Segoe UI';">&lt;Head&gt;</span>`,
          command: (e) => e.runCommand("head-settings"),
        },
        {
          id: "undo",
          className: "fa fa-undo",
          command: (e) => e.runCommand("core:undo"),
        },
        {
          id: "redo",
          className: "fa fa-repeat",
          command: (e) => e.runCommand("core:redo"),
        },
        {
          id: "visibility",
          active: false, // active by default
          className: "fa fa-th",
          command: "sw-visibility", // Built-in command
        },
      ],
    },
    {
      id: "panel-switcher",
      el: ".panel__switcher",
      buttons: [
        {
          id: "show-content",
          active: true,
          label: "CONTENT",
          command: "show-content",
          // Once activated disable the possibility to turn it off
          togglable: false,
        },
        {
          id: "show-style",
          active: false,
          label: "DESIGN",
          command: "show-styles",
          togglable: false,
        },
        {
          id: "show-layers",
          active: false,
          label: "LAYERS",
          command: "show-layers",
          // Once activated disable the possibility to turn it off
          togglable: false,
        },
      ],
    },
  ],
};

export const basicActionsPanel = {
  id: "basic-actions",
  el: "#main-buttons",
  buttons: [
    {
      id: "set-device-desktop",
      label: `<img src=${
        process.env.PUBLIC_URL + "/resources/desktop.svg"
      } alt=""/><span>Desktop</span>`,
      command: "set-device-desktop",
      active: 1,
    },
    {
      id: "set-device-mobile",
      label: `<img src=${
        process.env.PUBLIC_URL + "/resources/phone.svg"
      } alt=""/><span>Mobile</span>`,
      command: "set-device-mobile",
    },
    {
      id: "preview",
      label: `<img src=${
        process.env.PUBLIC_URL + "/resources/preview.svg"
      } alt=""/><span>Preview</span>`,
      command: "preview",
    },
    {
      id: "export",
      label: `<img src=${
        process.env.PUBLIC_URL + "/resources/publish.svg"
      } alt=""/><span>Publish</span>`,
      command: "export-template",
    },
    {
      id: "import",
      label: `<img src=${
        process.env.PUBLIC_URL + "/resources/code.svg"
      } alt=""/><span>Edit</span>`,
      command: "html-edit",
    },
    {
      id: "screenshot",
      label: `<img src=${
        process.env.PUBLIC_URL + "/resources/publish.svg"
      } alt=""/><span>Screenshot</span>`,
      command: "screenshot",
    },
  ],
};
