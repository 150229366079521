export const overlayElement = (editor) => {
  editor.DomComponents.addType("overlay", {
    // Special function which loops throught nodes in canvas and if condition is met returns
    // object which indicates that node is of that specific type.
    isComponent: (el) => {
      if (el.tagName === "DIV") {
        if (el.className && el.className.includes("gjs-overlay")) {
          return { type: "overlay" }; //component type, eg. `text`, `image`, `video`, etc.
        }
      }
    },
    model: {
      defaults: {
        tagName: "div", //HTML tag of the component, eg. `span`. Default: `div`
        draggable: "[data-gjs-type=wrapper]", // You can also specify a query string to indentify elements,
        // eg. `'.some-class[title=Hello], [data-gjs-type=column]'` means you can drag the component only inside elements
        // containing `some-class` class and `Hello` title, and `column` components.
        droppable: true, // Indicates if it's possible to drop other components inside.
        //components: flashPlayerWindows,
        // Component's traits.
        overlaySlider: "0.1",
        preset: null,
        traits: [
          {
            type: "overlay-slider",
            name: "overlaySlider",
            changeProp: 1,
            min: "0",
            max: "1",
            step: "0.1",
            label: "Outline ",
          },
        ],
        //style: {},
      },
    },
    view: {
      init() {
        // Call specific functions when model properties change.
        this.listenTo(this.model, "change:overlaySlider", this.onOverlayChange);
      },
      onOverlayChange(model) {
        let previousStyle = model.getStyle();
        let currentOverlay = model.get("overlaySlider");
        model.setStyle({
          ...previousStyle,
          "background-color": `rgba(0, 0, 0, ${currentOverlay})`,
        });
      },
      //   .modal-window {
      //     position: fixed;
      //     top: 0;
      //     left: 0;
      //     width: 100%;
      //     height: 100%;
      //     background-color: rgba(0, 0, 0, 0.4);
      //   }
    },
  });
};
