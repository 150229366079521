export const modalElement = (editor, res) => {
  editor.DomComponents.addType("modal-window", {
    isComponent: (el) => {
      if (el.tagName === "DIV") {
        if (el.className && el.className.includes("modal-window")) {
          const result = { type: "modal-window" };
          return result;
        }
      }
    },
    model: {
      defaults: {
        tagName: "div",
        attributes: {
          class: "modal-window",
        },
        draggable: "[data-gjs-type=wrapper]", // true = moze da se draguje u bilo koji element
        droppable: false,
        preset: null,
        animation: null,
        animationDelay: 0,
        animationDuration: 1,
        boxPositionH: null,
        boxPositionV: null,
        overlay: "0.1",
        traits: [
          {
            type: "select-preset",
            name: "preset",
            changeProp: 1,
            options: res,
            label: "More templates: ",
          },
          // {
          //   type: "custom-box-positioning",
          //   label: "Position",
          //   name: "boxPosition",
          // },
          {
            type: "animations-selector",
            name: "animation",
            changeProp: 1,
            label: "Select animation",
          },
          {
            type: "number",
            label: "Animation delay(in seconds): ",
            name: "animationDelay",
            placeholder: "0-5",
            changeProp: 1,
            min: 0, // Minimum number value
            max: 5, // Maximum number value
            step: 0.1, // Number of steps
          },
          {
            type: "number",
            label: "Animation duration(in seconds): ",
            name: "animationDuration",
            placeholder: "0-5",
            changeProp: 1,
            min: 0, // Minimum number value
            max: 5, // Maximum number value
            step: 0.1, // Number of steps
          },
        ],
      },
    },

    view: {
      tagName: "div", // po defaultu treba da bude isti tag kao od modela
      events: {
        click: "clickOnModal", // ako treba za neki inner elemenat 'dblclick .inner-el': 'innerElClick',
      },
      clickOnModal(e) {}, // dummy event handler
      init() {
        // Na promjenu tipa notifikacije poziva se opet render
        this.listenTo(this.model, "change:preset", this.onPresetChange);
        this.listenTo(this.model, "change:overlay", this.onOverlayChange);
        this.listenTo(
          this.model,
          "change:animation change:animationDelay change:animationDuration",
          this.onAnimationChange
        );
        // this.listenTo(
        //   this.model,
        //   "change:boxPositionH change:boxPositionV",
        //   this.onBoxPositionChange
        // );
      },

      // onBoxPositionChange(model) {
      //   let previousStyle = model.getStyle();
      //   let boxPositionH = model.get("boxPositionH");
      //   let boxPositionV = model.get("boxPositionV");
      //   console.log("usao");
      //   if (boxPositionH) {
      //     switch (boxPositionH) {
      //       case "left":
      //         model.setStyle({
      //           ...previousStyle,
      //           position: "absolute",
      //           left: "0",
      //           right: "auto",
      //           top: "auto",
      //           //right: "auto",
      //           // transform: `${
      //           //   boxPositionV === "middle" ? previousStyle.transform : "none"
      //           // }`,
      //         });
      //         break;
      //       case "center":
      //         model.setStyle({
      //           ...previousStyle,
      //           position: "absolute",
      //           left: "0",
      //           right: "0",
      //           top: "0",
      //           // transform: `${
      //           //   boxPositionV === "middle"
      //           //     ? "translate(-50%,-50%)"
      //           //     : "translateX(-50%)"
      //           // }`,
      //         });
      //         break;
      //       case "right":
      //         model.setStyle({
      //           ...previousStyle,
      //           position: "absolute",
      //           right: "0",
      //           top: "auto",
      //           left: "auto",
      //           //left: "auto",
      //           transform: `${
      //             boxPositionV === "middle" ? previousStyle.transform : "none"
      //           }`,
      //         });
      //         break;
      //       default:
      //     }
      //   }
      // if (boxPositionV) {
      //   switch (boxPositionV) {
      //     case "top":
      //       model.setStyle({
      //         ...previousStyle,
      //         position: "absolute",
      //         top: "0",
      //         left: "auto",
      //         bottom: "auto",
      //         right: "auto",
      //         //bottom: "auto",
      //         transform: `${
      //           boxPositionH === "center" ? previousStyle.transform : "none"
      //         }`,
      //       });
      //       break;
      //     case "middle":
      //       model.setStyle({
      //         ...previousStyle,
      //         position: "absolute",
      //         top: "50%",
      //         left: `${boxPositionH === "center" ? "50%" : "auto"}`,
      //         bottom: "auto",
      //         right: "auto",
      //         transform: `${
      //           boxPositionH === "center"
      //             ? "translate(-50%,-50%)"
      //             : "translateY(-50%)"
      //         }`,
      //       });
      //       break;
      //     case "bottom":
      //       model.setStyle({
      //         ...previousStyle,
      //         position: "absolute",
      //         bottom: "0",
      //         top: "auto",
      //         left: "auto",
      //         right: "auto",
      //         transform: `${
      //           boxPositionH === "center" ? previousStyle.transform : "none"
      //         }`,
      //       });
      //       break;
      //     default:
      //   }
      // }
      // },

      onPresetChange(model) {
        if (model.get("preset")) {
          const filtered = res.filter(
            (el) => el.id.toString() === model.get("preset")
          );
          if (
            filtered[0].content.indexOf("flash-player-mac-settings") !== -1 ||
            editor.getCss().indexOf(`font-family:"Open Sans"`) !== -1
          ) {
            if (!editor.getCss().includes("@font-face")) {
              editor.setStyle(`${editor.getCss()} @font-face {
                      font-family: 'Open Sans';
                      src: url(${
                        process.env.PUBLIC_URL +
                        "/resources/OpenSans-Regular.ttf"
                      })
                  }`);
            }
            if (
              localStorage
                .getItem("head-content")
                .indexOf("family=Open+Sans") === -1
            ) {
              let newHead =
                localStorage.getItem("head-content") +
                `<link href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap" rel="stylesheet">`;
              localStorage.setItem("head-content", newHead);
            }
            model.setAttributes({ class: `${filtered[0].title} modal-window` });
            model.components(filtered[0].content);
          } else {
            model.setAttributes({ class: `${filtered[0].title} modal-window` });
            model.components(filtered[0].content);
          }
        }
      },

      onOverlayChange(model) {
        let previousStyle = model.getStyle();
        let currentOverlay = model.get("overlay");
        model.setStyle({
          ...previousStyle,
          "background-color": `rgba(0, 0, 0, ${currentOverlay})`,
        });
      },

      onAnimationChange(model) {
        let previousStyle = model.getStyle();
        let currentAnimation = model.get("animation");
        let currentDelay = model.get("animationDelay");
        let currentDuration = model.get("animationDuration");
        if (currentAnimation !== "false") {
          model.setStyle({
            ...previousStyle,
            "animation-timing-function": "ease",
            "animation-fill-mode": "both",
            "animation-direction": "forwards",
          });
          switch (currentAnimation) {
            case "fade-in":
              model.setStyle({
                ...previousStyle,
                opacity: "0",
                "animation-duration": `${currentDuration}s`,
                "animation-name": "fade-in",
                "animation-delay": `${currentDelay}s`,
              });
              break;
            case "pulse":
              model.setStyle({
                ...previousStyle,
                opacity: "1",
                "animation-duration": `${currentDuration}s`,
                "animation-name": "pulse",
                "animation-delay": `${currentDelay}s`,
              });
              break;
            case "popright":
              model.setStyle({
                ...previousStyle,
                opacity: "0",
                "animation-duration": `${currentDuration}s`,
                "animation-name": "popright",
                "animation-delay": `${currentDelay}s`,
              });
              break;
            case "slideInDown":
              model.setStyle({
                ...previousStyle,
                opacity: "1",
                "animation-duration": `${currentDuration}s`,
                "animation-name": "slideInDown",
                "animation-delay": `${currentDelay}s`,
              });
              break;
            case "scaleIn":
              // model.get("components").each((child) =>
              //   child.setStyle({
              //     ...child.getStyle(),
              //     opacity: "1",
              //     "animation-name": "scaleIn",
              //     "animation-duration": `${currentDuration}s`,
              //     "animation-delay": `${currentDelay}s`,
              //     "animation-timing-function": "ease",
              //     "animation-fill-mode": "both",
              //   })
              // );
              model.setStyle({
                ...model.getStyle(),
                opacity: "1",
                "animation-name": "scaleIn",
                "animation-duration": `${currentDuration}s`,
                "animation-delay": `${currentDelay}s`,
                "animation-timing-function": "ease",
                "animation-fill-mode": "both",
              });

              break;
            default:
          }
        } else if (currentAnimation === "false") {
          model.setStyle({
            ...previousStyle,
            opacity: "1",
            "animation-name": "none",
          });
        }
      },
      onRender({ model, editor }) {
        const checkForCss = editor.CssComposer.getRule(`#${model.ccid}`);
        if (checkForCss) {
          const modelStyle = checkForCss.attributes.style;
          if (modelStyle["animation-name"]) {
            model.set("animation", modelStyle["animation-name"]);
          }
          if (modelStyle["animation-delay"]) {
            //model.animationDelay = modelStyle["animation-delay"];
            const aDel = modelStyle["animation-delay"];
            if (aDel.length > 1)
              model.set(
                "animationDelay",
                parseFloat(aDel.substring(0, aDel.length - 1))
              );
            else model.set("animationDelay", 0);
          }
          if (modelStyle["animation-duration"]) {
            //model.animationDuration = modelStyle["animation-duration"];
            const aDur = modelStyle["animation-duration"];
            if (aDur.length > 1)
              model.set(
                "animationDuration",
                parseFloat(aDur.substring(0, aDur.length - 1))
              );
            else model.set("animationDuration", 0);
          }
        }
        let prev = model.getStyle();
        if (!prev.position) {
          model.setStyle({
            ...prev,
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
          });
        }
      },
    },
  });
};
