import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import styles from "./PageNotFound.module.css";
import React from "react";

export const PageNotFound = ({ from }) => {
  useEffect(() => {
    if (from) {
      // replace browser url to show original url instead of page-not-found:
      window.history.replaceState(null, "", from);
    }
  }, [from]);

  const history = useHistory();

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>404</h1>
      <p>Page Not Found</p>
      <button onClick={() => history.goBack()} className={styles.goBack}>
        Go Back
      </button>
    </div>
  );
};
