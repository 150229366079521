import React from "react";
import { Tag } from "antd";
import { PlusCircleOutlined, EditOutlined } from "@ant-design/icons";

export const displayActionStatus = path => {
  if (path.includes("template")) {
    if (path.includes("new")) {
      return (
        <Tag icon={<PlusCircleOutlined />} color="success">
          Creating Template
        </Tag>
      );
    } else if (path.includes("edit")) {
      return (
        <Tag icon={<EditOutlined />} color="processing">
          Editing Template
        </Tag>
      );
    }
  } else if (path.includes("lp")) {
    if (path.includes("new")) {
      return (
        <Tag icon={<PlusCircleOutlined />} color="success">
          Creating Landing Page
        </Tag>
      );
    } else if (path.includes("edit")) {
      return (
        <Tag icon={<EditOutlined />} color="processing">
          Editing Landing Page
        </Tag>
      );
    }
  }
};
