import React, { useState, useEffect } from "react";
import { dateFormater } from "../../services/helpers";

import {
  Table,
  Tag,
  Tooltip,
  Button,
  Space,
  Input,
  message,
  Popconfirm,
  Spin,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import styles from "./LandingPages.module.css";
import { landingPagesService } from "../../services/landingPages";

const { Search } = Input;

export const LandingPages = ({ templateId }) => {
  const [landingPages, setLandingPages] = useState([]);
  const [loading, setLoading] = useState(false);
  const columns = [
    { title: "Id", dataIndex: "id", key: "id" },
    {
      title: "Title",
      dataIndex: "title",
      render: (title, record) => (
        <span
          onClick={() =>
            localStorage.setItem(
              "current-landing",
              JSON.stringify({
                titleLand: record.title,
                descriptionLand: record.description,
                statusLand: record.status,
                tagsLand: record.tags,
                landingId: record.id,
              })
            )
          }
        >
          <Tooltip title="Edit">
            <Link to={`/generator/lp/${record.id}/edit`}>{title}</Link>
          </Tooltip>
        </span>
      ),
      key: "title",
    },
    {
      title: "Tags",
      dataIndex: "tags",
      render: (tags) => {
        return tags.map((tag) => {
          return (
            <Tag color="geekblue" key={tag}>
              {tag.toUpperCase()}
            </Tag>
          );
        });
      },
      key: "tags",
    },
    {
      title: "Date Created",
      dataIndex: "created_at",
      render: (date) => dateFormater(date),
      key: "created_at",
    },
    {
      title: "Last Edited",
      dataIndex: "updated_at",
      render: (date) => dateFormater(date),
      key: "updated_at",
    },
    { title: "Creator", dataIndex: "creator", key: "creator" },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) =>
        status ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="volcano">Inactive</Tag>
        ),
      key: "status",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (record) => {
        return (
          <Space size="small">
            <Popconfirm
              title="Delete Landing Page?"
              onConfirm={() => handleLandingDelete(record)}
              okText="Delete"
              cancelText="Cancel"
              icon={<DeleteOutlined style={{ color: "red" }} />}
            >
              <Button
                danger
                shape="circle"
                icon={<DeleteOutlined />}
                size="small"
              />
            </Popconfirm>
            <Tooltip title="Edit">
              <Link to={`/generator/lp/${record.id}/edit`}>
                <Button
                  type="primary"
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() =>
                    localStorage.setItem(
                      "current-landing",
                      JSON.stringify({
                        titleLand: record.title,
                        descriptionLand: record.description,
                        statusLand: record.status,
                        tagsLand: record.tags,
                        landingId: record.id,
                      })
                    )
                  }
                  size="small"
                />
              </Link>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const handleLandingDelete = async (record) => {
    const messageKey = "delete";
    message.loading({
      content: "Deleting Landing Page...",
      key: messageKey,
      duration: 0,
    });
    try {
      await landingPagesService.deleteLanding(record.id);
      const landing_pages = landingPages.landing_pages.filter(
        (landing) => landing.id !== record.id
      );
      setLandingPages({ ...landingPages, landing_pages });
      message.success({
        content: "Successfully deleted landing page!",
        duration: 3,
        key: messageKey,
      });
    } catch (e) {
      message.error({
        content: "Failed to delete landing page, check your permissions!",
        duration: 3,
        key: messageKey,
      });
    }
  };

  useEffect(() => {
    const initialFetch = async () => {
      setLoading(true);
      try {
        const response = await landingPagesService.getLandingPages(templateId);
        setLandingPages(response);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    initialFetch();
  }, [templateId]);

  const searchByTitle = async (value) => {
    setLoading(true);
    try {
      const response = await landingPagesService.getLandingByTitle(
        value,
        localStorage.getItem("father_template")
      );
      setLandingPages(response);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const searchById = async (id) => {
    setLoading(true);
    try {
      const response = await landingPagesService.getLandingById(
        id,
        localStorage.getItem("father_template")
      );
      setLandingPages(response);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div>
        <Tooltip placement="right" color="blue">
          <p className={styles.templateTableTitle}>
            {`${
              landingPages.father_template
                ? landingPages.father_template.title
                : "loading..."
            } #${templateId}`}
          </p>
        </Tooltip>
      </div>
      <Search
        placeholder="by Title"
        onSearch={searchByTitle}
        style={{ width: 300, marginLeft: 10, marginBottom: 10 }}
      />
      <Search
        placeholder="by ID"
        onSearch={searchById}
        style={{ width: 150, marginLeft: 10, marginBottom: 10 }}
      />
      <Link to={`/generator/lp/${templateId}/new`}>
        <Button type="primary" style={{ marginLeft: 20 }}>
          New Landing Page
        </Button>
      </Link>
      <Spin spinning={loading}>
        <Table
          columns={columns}
          expandable={{
            expandedRowRender: (record) => (
              <div className={styles.tableExpandContent}>
                <img
                  src={record.image}
                  alt="Landing Page Preview"
                  className={styles.previewPageImage}
                />
              </div>
            ),
            rowExpandable: (record) => record.name !== "Not Expandable",
          }}
          dataSource={landingPages.landing_pages}
          rowKey="title"
        />
      </Spin>
    </>
  );
};
