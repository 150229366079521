export const deleteCookie = (name, path, domain) => {
  const pathPart = path ? `;path=${path}` : '';
  const domainPart = domain ? `;domain=${domain}` : '';
  document.cookie = `${name}=${pathPart}${domainPart};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
};

export const clearCSRFCookies = () => {
  deleteCookie('csrftoken', '/');
  const domain = window.location.hostname;
  const domainParts = domain.split('.');

  for (let i = 0; i < domainParts.length; i++) {
    const cookieDomain = (i > 0 ? '.' : '') + domainParts.slice(i).join('.');
    deleteCookie('csrftoken', '/', cookieDomain);
  }
};
