import { blockManagerSettings } from "./grapesSettings/blockManagerSettings";
import { panelsSettings } from "./grapesSettings/panelsSettings";
import { styleManagerSettings } from "./grapesSettings/styleManagerSettings";
import { devicesManagerSettings } from "./grapesSettings/devicesManagerSettings";
import {
  storageManagerSettings,
  indexeddbSettings,
} from "./grapesSettings/storageManagerSettings";
import { traitManagerSettings } from "./grapesSettings/traitManagerSettings";
// import { assetManagerSettings } from "./grapesSettings/assetManagerSettings";
import { defaultCommands } from "./grapesSettings/defaultCommands";

import { overlayElement } from "./grapesElements/overlayElement";
import { timerElement } from "./grapesElements/timerElement";
import { buttonElement } from "./grapesElements/buttonElement";
import { plugElement } from "./grapesElements/plugElement";
import { audioElement } from "./grapesElements/audioElement";
import { modalImport } from "./grapesElements/modalImport";
import { modalHead } from "./grapesElements/modalHead";
import {
  colorPickerSettings,
  layerManagerSettings,
  selectorManagerSettings,
} from "./utils/consts";
import { dateElement } from "./grapesElements/dateElement";
import { basicBlocks } from "./grapesElements/basicBlocks";

export const rootSettings = {
  container: "#gjs",
  fromElement: true,
  height: "80vh",
  protectedCss: "*{ box-sizing: border-box; } body { min-height: 100vh; }",
  width: "auto",
  selectorManager: selectorManagerSettings,
  blockManager: blockManagerSettings,
  panels: panelsSettings,
  traitManager: traitManagerSettings,
  styleManager: styleManagerSettings,
  deviceManager: devicesManagerSettings,
  storageManager: storageManagerSettings,
  //assetManager: assetManagerSettings,
  layerManager: layerManagerSettings,
  allowScripts: 1,
  plugins: [
    defaultCommands,
    indexeddbSettings,
    overlayElement,
    timerElement,
    buttonElement,
    plugElement,
    audioElement,
    modalImport,
    modalHead,
    dateElement,
    "grapesjs-style-gradient",
    "grapesjs-parser-postcss",
    basicBlocks,
  ],
  removeEmptyTextNodes: true,
  colorPicker: colorPickerSettings,
  avoidInlineStyle: false,
  forceClass: false,
};
