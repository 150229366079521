import { notification } from "antd";

export const openNotificationWithIcon = (
  type,
  message,
  description,
  duration
) => {
  notification[type]({ message, description, duration });
};

export const openErrorNotification = ({ message, description, duration }) => {
  openNotificationWithIcon("error", message, description, duration);
};

export const openSuccessNotification = ({ message, description, duration }) => {
  openNotificationWithIcon("success", message, description, duration);
};
export const openWarningNotification = ({ message, description, duration }) => {
  openNotificationWithIcon("warning", message, description, duration);
};
