import React from "react";
import { Form, InputNumber, Button, Col, Row, message } from "antd";

export const FirstForm = ({ setQuestionnaireValue, changeCurrentForm }) => {
  const onFinish = (values) => {
    setQuestionnaireValue(values);
    changeCurrentForm(2);
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Fix values in order to continue");
  };

  return (
    <Form
      span={24}
      justify="center"
      name="firstform"
      initialValues={{ numberOfQuestions: 1, numberOfAnswers: 1 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row justify="start">
        <Col span={22} offset={2}>
          <Form.Item
            name="numberOfQuestions"
            label="Please select number of questions!"
            rules={[
              { required: true, message: "Please input a valid number!" },
            ]}
          >
            <InputNumber min={1} max={10} />
          </Form.Item>
          <Form.Item
            name="numberOfAnswers"
            label="Please select number of answers!"
            rules={[
              { required: true, message: "Please input a valid number!" },
            ]}
          >
            <InputNumber min={1} max={10} />
          </Form.Item>
        </Col>
      </Row>
      <Col span={4} offset={20}>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Next
          </Button>
        </Form.Item>
      </Col>
    </Form>
  );
};
