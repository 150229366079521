import FileSaver from "file-saver";

export const modalImport = (editor) => {
  var pfx = editor.getConfig().stylePrefix;
  var modal = editor.Modal;
  var cmdm = editor.Commands;
  var htmlViewer = editor.CodeManager.getViewer("CodeMirror").clone();
  var cssViewer = editor.CodeManager.getViewer("CodeMirror").clone();
  var container = document.createElement("div");
  var btnEdit = document.createElement("button");
  let btnExport = document.createElement("button");
  let buttonsWrapper = document.createElement("div");
  buttonsWrapper.classList.add("import-buttons-wrapper");
  cssViewer.set({
    codeName: "css",
    readOnly: 0,
    theme: "hopscotch",
    autoBeautify: true,
    autoCloseTags: true,
    autoCloseBrackets: true,
    lineWrapping: true,
    styleActiveLine: true,
    smartIndent: true,
    indentWithTabs: true,
  });
  htmlViewer.set({
    // codeName: "htmlmixed",
    readOnly: 0,
    theme: "hopscotch",
    autoBeautify: true,
    autoCloseTags: true,
    autoCloseBrackets: true,
    lineWrapping: true,
    styleActiveLine: true,
    smartIndent: true,
    indentWithTabs: true,
  });

  container.style.display = "grid";
  container.style.gridTemplateColumns = "1fr 1fr";
  container.style.fontSize = "12px";
  container.style.gridColumnGap = "1rem";
  btnEdit.innerHTML = "SAVE";
  btnEdit.className =
    pfx + "btn-prim " + pfx + "btn-import custom-save-import-button";
  btnExport.innerHTML = "EXPORT";
  btnExport.className =
    pfx + "btn-prim " + pfx + "btn-import custom-save-import-button";
  btnExport.onclick = () => {
    console.log("export");
    var blob = new Blob(
      [
        `<html><head>${localStorage.getItem(
          "head-content"
        )}</head><body><style>${editor.getCss()}</style>${editor.getHtml()}</body></html>`,
      ],
      { type: "text/plain;charset=utf-8" }
    );
    FileSaver.saveAs(blob, "ExportedPage.html");
  };
  btnEdit.onclick = function () {
    let htmlCode = htmlViewer.editor.getValue();
    let cssCode = cssViewer.editor.getValue();
    let code = `<style> ${cssCode}</style> ${htmlCode}`;
    editor.DomComponents.getWrapper().set("content", "");
    editor.CssComposer.clear();
    editor.setComponents(code.trim());
    modal.close();
  };

  cmdm.add("html-edit", {
    run: function (editor, sender) {
      sender && sender.set("active", 0);
      let viewer2 = cssViewer.editor;
      let viewer1 = htmlViewer.editor;
      modal.setTitle("Edit / Import code:");
      if (!viewer2 && !viewer1) {
        var txtarea = document.createElement("textarea");
        container.appendChild(txtarea);
        buttonsWrapper.appendChild(btnEdit);
        buttonsWrapper.appendChild(btnExport);
        container.appendChild(buttonsWrapper);
        cssViewer.init(txtarea);
        htmlViewer.init(txtarea);
        viewer2 = cssViewer.editor;
        viewer1 = htmlViewer.editor;
      }
      var InnerHtml = editor.getHtml();
      var Css = editor.getCss({ avoidProtected: true });
      modal.setContent("");
      modal.setContent(container);
      cssViewer.setContent(Css);
      htmlViewer.setContent(InnerHtml);
      modal.open();
      viewer2.refresh();
      viewer1.refresh();
    },
  });
};
