import React, { useState, useEffect } from "react";
import { Radio, Spin, Tooltip, Input } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import { PlusOutlined } from "@ant-design/icons";

import styles from "./Templates.module.css";
import TemplateCard from "./components/TemplateCard";
import { Link } from "react-router-dom";
import { templatesService } from "../../services/templates";
import Checkbox from "antd/lib/checkbox/Checkbox";

export const Templates = ({
  currentCategory,
  setCurrentCategory,
  setModalQuestionnaire,
  setModalSurvey2Search
}) => {
  const [templateList, setTemplateList] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingTemplate, setLoadingTemplate] = useState(false);
  const [search, setSearch] = useState("");
  const [isAdult, setIsAdult] = useState(false);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleIsAdult = (e) => {
    setIsAdult(e.target.checked);
  }

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      try {
        const response = await templatesService.getTemplateCategories();
        setAllCategories(response);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchTemplatesByCat = async () => {
      setLoadingTemplate(true);
      try {
        const response = await templatesService.getTemplatesFiltered(
          currentCategory,
          search,
          isAdult
        );
        setTemplateList(response);
      } catch (e) {
        console.log(e);
      } finally {
        setLoadingTemplate(false);
      }
    };
    fetchTemplatesByCat();
  }, [currentCategory, search, isAdult]);

  const onChange = (e) => {
    setCurrentCategory(e.target.value);
    localStorage.setItem("current_category", e.target.value);
    setSearch("");
  };

  const renderTemplates = (templates) => {
    return (
      <Spin spinning={loadingTemplate}>
        <div className={styles.templatesContent}>
          <Link to={"/generator/template/new"}>
            <div className={styles.addTemplateBtn}>
              <Tooltip title="Add New Template">
                <PlusOutlined />
              </Tooltip>
            </div>
          </Link>
          {templates.map((template) => (
            <TemplateCard
              key={template.id}
              template={template}
              setModalQuestionnaire={setModalQuestionnaire}
              setModalSurvey2Search={setModalSurvey2Search}
            />
          ))}
        </div>
      </Spin>
    );
  };

  const renderCategoryButtons = (categories) => {
    return (
      <Spin spinning={loading}>
        <Radio.Group
          buttonStyle="solid"
          onChange={onChange}
          value={currentCategory}
        >
          {categories &&
            categories.map((category) => (
              <Radio.Button key={category.title} value={category.title}>
                {category.title}
              </Radio.Button>
            ))}
        </Radio.Group>
      </Spin>
    );
  };
  
  return (
    <div>
      <div className={styles.categoryMenu}>
        {renderCategoryButtons(allCategories)}
        <Input
          placeholder="Input search text"
          value={search}
          onChange={handleSearchChange}
          suffix={<SearchOutlined />}
        />
        <div><span className={styles.adultLabel}>Display adult:</span><Checkbox onChange={handleIsAdult} /></div>
      </div>

      {renderTemplates(templateList)}
    </div>
  );
};
