import { callApi } from "./api";

class Survey2SearchService {
  async getTranslations({verticals}) {
    return await callApi({ url: "/translations",data:verticals,method:"POST" });
  }

}

export const survey2SearchService = new Survey2SearchService();
