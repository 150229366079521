export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};
export const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};

export const convertSnippetToRawText = (str) => {
  let newElement = document.createElement("div");
  newElement.innerHTML = str;
  const filtered = newElement.querySelector("#snippet");
  if (filtered) {
    let snippetEl = document.createTextNode(`${filtered.innerHTML}`);
    let finalElement = document.createElement("div");
    newElement.removeChild(filtered);
    finalElement.innerHTML = snippetEl.textContent + newElement.innerHTML;
    return finalElement.innerHTML;
  } else {
    return newElement.innerHTML;
  }
};

// export const createLandingObject = (
//   title,
//   description,
//   tags,
//   status,
//   category
// ) => {

// };

export const createTemplateObject = (title, description, category) => {};
