export const timerElement = (editor) => {
  editor.DomComponents.addType("timer", {
    isComponent: (el) => {
      if (el.tagName === "DIV") {
        if (el.className && el.className.includes("gjs-timer")) {
          const result = { type: "timer" };
          if (el.textContent) result.components = el.textContent;
          return result;
        }
      }
    },
    model: {
      defaults: {
        tagName: "div",
        draggable: true,
        droppable: true,
        components: `Offer expires in <span class="inner-time">00:00</span> minutes!`,
        startfrom: null,
        endtext: "Offer has expired",
        traits: [
          {
            type: "number",
            name: "startfrom",
            changeProp: 1,
            label: "Please input time in (seconds):",
            placeholder: "seconds",
            min: 0, // Minimum number value
            max: 1000, // Maximum number value
            step: 1, // Number of steps
          },
          {
            label: "Ending text:",
            name: "endtext",
            changeProp: 1,
          },
        ],
        style: {
          display: "inline-block",
        },

        // Core timer functionality script that listens to property changes and updates timer values.
        script: function () {
          let startFrom = `{[ startfrom ]}`;
          let display = this.querySelector(".inner-time");
          if (!display) {
            this.innerHTML = `Offer expires in <span class="inner-time">00:00</span> minutes!`;
            display = this.querySelector(".inner-time");
          }
          if (this.timerInterval) {
            clearInterval(this.timerInterval);
          }

          if (startFrom) {
            const startTimer = (duration, elem) => {
              let timer = duration,
                minutes,
                seconds;
              let interval = setInterval(() => {
                minutes = parseInt(timer / 60, 10);
                seconds = parseInt(timer % 60, 10);

                minutes = minutes < 10 ? "0" + minutes : minutes;
                seconds = seconds < 10 ? "0" + seconds : seconds;
                elem.textContent = minutes + ":" + seconds;

                if (--timer < 0) {
                  elem.parentNode.textContent = "{[ endtext ]}";
                  clearInterval(interval);
                }
              }, 1000);
              this.timerInterval = interval;
            };

            startTimer(startFrom, display);
          }
        },
      },
    },
    view: {
      tagName: "div", // po defaultu treba da bude isti tag kao od modela
      init() {
        this.listenTo(
          this.model,
          "change:startfrom change:endtext",
          this.updateScript
        );
      },
    },
  });
};
