import React from "react";
import { useMount } from "react-use";

import { IAM_URL } from "../../config";
import styles from "./Login.module.css";

export const Login = () => {
  useMount(() => {
    window.location.href = `${IAM_URL}/login?service_redirect_url=${window.location.origin}`;
  });

  return (
    <div className={styles.loginFormContainer}>
      <div className={styles.loginFormWrapper}>
        <h5 className={styles.billingText}>LP Generator</h5>
      </div>
    </div>
  );
}
