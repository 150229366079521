export const dateElement = (editor) => {
  editor.DomComponents.addType("date", {
    isComponent: (el) => {
      if (el.tagName === "DIV") {
        if (el.className && el.className.includes("gjs-date")) {
          return { type: "date" };
        }
      }
    },
    model: {
      defaults: {
        tagName: "div",
        badgable: true,
        draggable: true,
        droppable: true,
        editable: true,
        format: "",
        datePicked: "",
        traits: [
          {
            type: "select",
            label: "Date Format",
            name: "format",
            changeProp: 1,
            options: [
              { name: "DD/MM/YYYY", value: "DD/MM/YYYY" },
              { name: "MM/DD/YYYY", value: "MM/DD/YYYY" },
              { name: "DD.MM.YYYY", value: "DD.MM.YYYY" },
              { name: "MM.DD.YYYY", value: "MM.DD.YYYY" },
            ],
          },
          {
            type: "date-input",
            label: "Input date",
            name: "datePicked",
            changeProp: 1,
          },
        ],
      },
    },
    view: {
      init() {
        this.listenTo(
          this.model,
          "change:datePicked change:format",
          this.onDatePickedChange
        );
        this.listenTo(this.model, "change:format", this.onChangeFormat);
      },
      onDatePickedChange(model) {
        const selectedDate = new Date(model.get("datePicked"));
        const sDay = selectedDate.getDate();
        const sMonth = selectedDate.getMonth() + 1;
        const sYear = selectedDate.getFullYear();
        const format = model.get("format");
        if (format && model.get("datePicked")) {
          if (format.indexOf("/") !== -1) {
            let newDate = format
              .split("/")
              .map((el) => (el === "DD" ? sDay : el === "MM" ? sMonth : sYear))
              .join("/");
            model.set("content", newDate);
          } else {
            let newDate = format
              .split(".")
              .map((el) => (el === "DD" ? sDay : el === "MM" ? sMonth : sYear))
              .join(".");
            model.set("content", newDate);
          }
        }
      },
      onChangeFormat(model) {
        model.attributes.attributes.dateformat = model.get("format");
      },
      onRender({ model, editor }) {
        let content = model.get("content");
        const format = model.getAttributes();
        let currentDate = new Date().toLocaleString().substring(0, 10);
        if (format.dateformat) {
          model.set("format", format.dateformat);
        } else {
          model.set("format", "MM/DD/YYYY");
        }
        if (!content) {
          model.set("content", currentDate);
          model.set("datePicked", currentDate);
        }
      },
    },
  });
};
