import { callApi } from "./api";

class LandingPagesService {
  async getLandingPages(templateId) {
    return await callApi({
      url: `/landing-pages?father_template=${templateId}`,
    });
  }
  async deleteLanding(id) {
    return await callApi({ url: `/landing-pages/${id}`, method: "DELETE" });
  }
  async getLandingByTitle(value, father_id) {
    return await callApi({
      url: `/landing-pages?title=${value}&father_template=${father_id}`,
    });
  }
  async getLandingById(id, father_id) {
    return await callApi({
      url: `/landing-pages?id=${id}&father_template=${father_id}`,
    });
  }
}

export const landingPagesService = new LandingPagesService();
