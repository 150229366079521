import qs from "query-string";

export const getQueryString = (queryParams) => {
  if (!queryParams) {
    return "";
  }

  return qs.stringify(queryParams, {
    skipEmptyString: true,
    skipNull: true,
    arrayFormat: "none",
  });
};
