import { callApi } from "./api";

class SaveService {
  async addNewTemplate(template) {
    return await callApi({ url: "/templates", data: template, method: "POST" });
  }

  async editTemplate(template, editId) {
    return await callApi({
      url: `/templates/${editId}`,
      data: template,
      method: "PUT",
    });
  }

  async addNewLanding(landing) {
    return await callApi({
      url: `/landing-pages`,
      data: landing,
      method: "POST",
    });
  }

  async editLanding(template, editId) {
    return await callApi({
      url: `/landing-pages/${editId}`,
      data: template,
      method: "PUT",
    });
  }
}

export const saveService = new SaveService();
