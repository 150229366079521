import { callApi } from "./api";

class QuestionnaireService {
  async getFrequentPrizes() {
    return await callApi({ url: "/frequent-prizes" });
  }
  async getSinglePrize(id) {
    return await callApi({ url: `/frequent-prizes/${id}` });
  }
}

export const questionnaireService = new QuestionnaireService();
