import "antd/dist/reset.css";

import { Layout } from "antd";
import React, { useEffect, useState } from "react";
import { Link, Route, Switch, useLocation, Redirect } from "react-router-dom";

import { QuestionnaireModal } from "../components/QuestionnaireModals/QuestionnaireModal";
import { LandingModal } from "../components/SaveModals/LandingModal";
import { TemplateModal } from "../components/SaveModals/TemplateModal";
import { Generator } from "../pages/Generator";
import { Home } from "../pages/Home";
import { LandingPages } from "../pages/LandingPages";
import { Templates } from "../pages/Templates";
import { displayActionStatus } from "../utils/layoutMain";
import styles from "./LayoutMain.module.css";
import { Survey2SearchModal } from "../components/Survey2SearchModal";

const { Header, Content, Sider } = Layout;

const LayoutMain = ({ children }) => {
  let location = useLocation();
  const [generatorVisible, setGeneratorVisible] = useState(true);
  const [actionType, setActionType] = useState("");
  const [currentCategory, setCurrentCategory] = useState(
    localStorage.getItem("current_category") || "Extensions"
  );
  const [darkMode, setDarkMode] = useState(false);
  const [modalTemplate, setTemplateModal] = useState(false);
  const [modalLanding, setLandingModal] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState({});
  const [currentLanding, setCurrentLanding] = useState({});
  const [modalQuestionnaire, setModalQuestionnaire] = useState(false);
  const [modalSurvey2Search, setModalSurvey2Search] = useState(false);

  useEffect(() => {
    setGeneratorVisible(location.pathname.includes("generator"));
    setActionType(location.pathname);
  }, [location]);

  const handleShowModal = () => {
    if (location.pathname.includes("/template/")) {
      setTemplateModal(true);
    } else if (location.pathname.includes("/lp")) {
      setLandingModal(true);
    }
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsed={generatorVisible}
        collapsedWidth={0}
        style={{ backgroundColor: "#012D6D" }}
      >
        <Link to={"/"}>
          <div className={styles.logo}>
            <img src={process.env.PUBLIC_URL + "/resources/logo.svg"} alt="" />
          </div>
        </Link>
      </Sider>
      <Layout style={{ backgroundColor: darkMode ? "#16213e" : "" }}>
        <Header className={styles.siteLayoutSubHeaderBackground}>
          {generatorVisible && (
            <>
              <div className={styles.logoDescContainer}>
                <Link to={"/templates"}>
                  <img
                    src={process.env.PUBLIC_URL + "/resources/logoDark.svg"}
                    alt=""
                    className={styles.logoHeaderDisplay}
                  />
                </Link>
                {generatorVisible && displayActionStatus(actionType)}
              </div>
              <div style={{ display: "flex" }}>
                <div className={styles.buttonsRight} id="main-buttons">
                  <Link to="/templates">
                    <span className="gjs-pn-btn" id={styles.btnFix}>
                      <img
                        src={process.env.PUBLIC_URL + "/resources/left.svg"}
                        alt=""
                      />
                      Go Back
                    </span>
                  </Link>
                  <span
                    className="gjs-pn-btn btn-last"
                    onClick={handleShowModal}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/resources/save.svg"}
                      alt=""
                    />
                    <span>Save</span>
                  </span>
                </div>
                <label className={styles.switch}>
                  <input
                    type="checkbox"
                    checked={darkMode}
                    onChange={() => setDarkMode(!darkMode)}
                  />
                  <span className={styles.slider}></span>
                </label>
              </div>
            </>
          )}
        </Header>
        <Content className={styles.layoutContent}>
          <div
            className={styles.siteLayoutBackground}
            style={{
              backgroundColor: `${generatorVisible ? "transparent" : "white"}`,
            }}
          >
            {children}
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => (
                  <Home setCurrentCategory={setCurrentCategory} {...props} />
                )}
              />
              <Route
                exact
                path="/templates"
                render={(props) => (
                  <Templates
                    currentCategory={currentCategory}
                    setCurrentCategory={setCurrentCategory}
                    setModalQuestionnaire={setModalQuestionnaire}
                    setModalSurvey2Search={setModalSurvey2Search}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/landing-pages/:id"
                render={(props) => (
                  <LandingPages templateId={props.match.params.id} {...props} />
                )}
              />
              <Route
                exact
                path="/generator/template/new"
                render={(props) => (
                  <Generator
                    setCurrentTemplate={setCurrentTemplate}
                    darkMode={darkMode}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/generator/template/:id/edit"
                render={(props) => (
                  <Generator
                    setCurrentTemplate={setCurrentTemplate}
                    id={props.match.params.id}
                    darkMode={darkMode}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/generator/lp/:id/new"
                render={(props) => (
                  <Generator
                    setCurrentLanding={setCurrentLanding}
                    id={props.match.params.id}
                    darkMode={darkMode}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/generator/lp/:id/edit"
                render={(props) => (
                  <Generator
                    setCurrentLanding={setCurrentLanding}
                    id={props.match.params.id}
                    darkMode={darkMode}
                    {...props}
                  />
                )}
              />
              <Route
                key="page-not-found"
                render={(props) => {
                  const { pathname, search, hash } = props.location;
                  const from = pathname + search + hash;
                  return (
                    <Redirect
                      to={{ pathname: "/page-not-found", state: { from } }}
                    />
                  );
                }}
              />
            </Switch>
            <TemplateModal
              currentTemplate={currentTemplate}
              showModal={modalTemplate}
              setShowModal={setTemplateModal}
            />
            <LandingModal
              currentLanding={currentLanding}
              showModal={modalLanding}
              setShowModal={setLandingModal}
            />
            <QuestionnaireModal
              showModal={modalQuestionnaire}
              setModalQuestionnaire={setModalQuestionnaire}
            />
             <Survey2SearchModal
              showModal={modalSurvey2Search}
              setModalSurvey2Search={setModalSurvey2Search}
            />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutMain;
