import React, { useEffect, useState } from "react";
import styles from "./Home.module.css";
import { Link } from "react-router-dom";
import { renderCards } from "../../services/home";
import { callApi } from "../../services/api";

export const Home = ({ setCurrentCategory }) => {
  const [typesList, setTypesList] = useState([]);

  useEffect(() => {
    callApi({ url: "/lp-types" }).then((res) => {
      setTypesList(res);
      localStorage.setItem("categories", JSON.stringify(res));
    });
  }, []);

  return (
    <div className={styles.homeContainer}>
      {renderCards(typesList, setCurrentCategory)}
      <p className={styles.homeText}>
        Or start from scratch and work with a{" "}
        <Link to={"/generator/template/new"} className={styles.homeCanvasLink}>
          blank canvas.
        </Link>
      </p>
    </div>
  );
};
