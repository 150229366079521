import React from "react";
import { Modal } from "antd";
import { Survey2SearchForm } from "./Survey2SearchForm";

export const Survey2SearchModal = ({ showModal, setModalSurvey2Search }) => {

  const onModalCancel = () => {
    setModalSurvey2Search(false);
  };

  return (
    <Modal
      title="Generate new Survey 2 Search LP"
      visible={showModal}
      footer={null}
      centered={true}
      onCancel={onModalCancel}
      width="60%"
      forceRender
    >
     <Survey2SearchForm />
    </Modal>
  );
};
