export const popRight = `@keyframes popright {
    0% {
      transform: translateX(930px);
      opacity: 0;
    }

    100% {
      transform: rotate(xx) translateX(430px);
      opacity: 1;
    }
  }`;

export const fadeIn = `@keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }`;

export const pulse = `@keyframes pulse {
    from {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  
    50% {
      -webkit-transform: scale3d(1.05, 1.05, 1.05);
      transform: scale3d(1.05, 1.05, 1.05);
    }
  
    to {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }`;

export const slideInDown = `@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}`;

export const scaleIn = `@keyframes scaleIn {
  0% {
    transform: scale(0);
    opacity: 0
  }

  100% {
    transform: rotate(xx) scale(1);
    opacity: 1
  }
}`;
