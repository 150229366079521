import { EventEmitter } from "../utils/event-emitter";

export class AuthState {
  loggedIn = false;

  eventEmitter = new EventEmitter();

  isLoggedIn() {
    return this.loggedIn;
  }

  setLoggedIn(loggedIn) {
    const prevLoggedIn = this.loggedIn;
    this.loggedIn = loggedIn;

    if (prevLoggedIn !== loggedIn) {
      this.eventEmitter.dispatchEvent(loggedIn);
    }
  }

  registerSubscriber(subscriber) {
    this.eventEmitter.registerSubscriber(subscriber);
  }

  unregisterSubscriber(subscriber) {
    this.eventEmitter.unregisterSubscriber(subscriber);
  }
}

export const authState = new AuthState();
