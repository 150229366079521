import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function TextEditor({ value, onChange, placeholder }) {
    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', {'size':[]}, {'color':[]
            }]
        ],
    };

    const formats = [
        'bold', 'italic', 'underline', 'size', 'color'
    ];

    const handleChange = (content, delta, source, editor) => {
        // Check if the editor content is just the placeholder empty value
        if (source === 'user') {
            onChange(content === '<p><br></p>' ? '' : content);
        }
    };

    return (
        <ReactQuill
            theme="snow"
            className='quill-editor'
            style={
                {

                }
            }
            value={value}
            onChange={handleChange}
            modules={modules}
            formats={formats}
            placeholder={placeholder}
        />
    );
}

export default TextEditor;
