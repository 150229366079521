import React, { useState } from "react";
import { Modal } from "antd";
import { FirstForm } from "./components/FirstForm";
import { SecondForm } from "./components/SecondForm";
import { ThirdForm } from "./components/ThirdForm";

export const QuestionnaireModal = ({ showModal, setModalQuestionnaire }) => {
  const [currentForm, changeCurrentForm] = useState(1);
  const [questionnaireValues, setQuestionnaireValue] = useState({});

  const onModalCancel = () => {
    setModalQuestionnaire(false);
    changeCurrentForm(1);
    setQuestionnaireValue({});
  };

  return (
    <Modal
      title="Generate new Questionnaire LP"
      visible={showModal}
      footer={null}
      centered={true}
      onCancel={onModalCancel}
      width={currentForm > 1 ? "60%" : 520}
      forceRender
    >
      {currentForm === 1 ? (
        <FirstForm
          changeCurrentForm={changeCurrentForm}
          setQuestionnaireValue={setQuestionnaireValue}
        />
      ) : currentForm === 2 ? (
        <SecondForm
          numOfQuestions={questionnaireValues.numberOfQuestions}
          numOfAnswers={questionnaireValues.numberOfAnswers}
          setQuestionnaireValue={setQuestionnaireValue}
          changeCurrentForm={changeCurrentForm}
        />
      ) : (
        <ThirdForm
          onModalCancel={onModalCancel}
          questionnaireValues={questionnaireValues}
        />
      )}
    </Modal>
  );
};
