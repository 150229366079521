export const fixString = (string) => string.toLowerCase().replace(/\s/g, "-");

export const fixImport = (content) => {
  let newElement = document.createElement("div");
  let newSnippetNode = document.createElement("div");
  newSnippetNode.id = "snippet";
  newElement.innerHTML = content;
  [...newElement.childNodes].forEach((el) => {
    let reg = /.*{{.+}}/gm;
    if (reg.test(el.textContent) && el.id !== "steps-script") {
      newSnippetNode.append(el);
      //console.log(el.textContent.match(/^{{.+}}$/g));
      //newElement.removeChild(el);
    }
  });
  if (newSnippetNode.textContent) {
    newElement.prepend(newSnippetNode);
  }

  return newElement.innerHTML;
};

export const dateFormater = (date) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let current_datetime = new Date(date);

  let seconds =
    current_datetime.getSeconds() < 10
      ? "0" + current_datetime.getSeconds()
      : current_datetime.getSeconds();
  let minutes =
    current_datetime.getMinutes() < 10
      ? "0" + current_datetime.getMinutes()
      : current_datetime.getMinutes();

  let formatted_date =
    monthNames[current_datetime.getMonth()] +
    " " +
    current_datetime.getDate() +
    " " +
    current_datetime.getFullYear() +
    ", " +
    current_datetime.getHours() +
    ":" +
    minutes +
    ":" +
    seconds;
  return formatted_date;
};

export const alertScript = (option, text, delay) => {
  if (option && text) {
    return delay && delay !== "0"
      ? `setTimeout( function (){ alert("${text}") } , ${delay} * 1000)`
      : `alert("${text}")`;
  }
};

export const onLeaveScript = (option, text) => {
  if (option) {
    if (text) {
      return `window.onunload = function (event) {
        event.returnValue = "${text}";
      };
      window.onbeforeunload = function (event) {
        event.returnValue = "${text}";
        return "${text}";
      };`;
    }
  } else {
    return `window.onunload = window.onbeforeunload = null;`;
  }
};

export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const generatreQuestionnareQA = (content, questValues) => {
  let wrapper = document.createElement("div");
  let questionWrapper = document.createElement("div");
  let rowElem = document.createElement("div");
  rowElem.classList.add("row");
  let question = rowElem.cloneNode(true);
  let answer = rowElem.cloneNode(true);
  wrapper.innerHTML = content;
  let questionnaireWrapper = wrapper.querySelector("#questionnaireWrapper");
  let answeredQuestions = wrapper.querySelector("#answeredQuestions");
  let prizesWrapper = wrapper.querySelector("#qu-prize");
  answeredQuestions.innerHTML = `You answered all (${
    Object.keys(questValues.questionnaireValues).length
  }/${Object.keys(questValues.questionnaireValues).length}) questions`;
  let answerElem = document.createElement("div");
  answerElem.innerHTML = `<div class="row qu-prize-row">
  <div class="col-xs-5 text-xs-right qu-item">
    <img src="" class="item-img"/>
  </div>
  <div class="col-xs-7 text-xs-left qu-prize-description">
    <span class="qu-prize-name">Apple Watch</span>
    <strong>Your price:</strong>
    <span class="qu-yourprice-value">
      $1 (DHL shipping cost) 
    </span>
    <br>
    <strong>Remaining quantity:</strong>
    <span class="qu-prizes-remaining">1 units</span>
    <br>
    <a href="{{FILE_URL}}" class="qu-take-it wtmtcy01 tracking_event05">
      Take it
    </a>
  </div>
</div>`;
  answer.innerHTML = `<div class="qu col-xs-12 col-md-8 col-md-offset-2"></div>`;
  question.innerHTML = `<div class="qu-group-header col-xs-12"></div>`;
  questionWrapper.innerHTML = `<div class="qu-group col-xs-12"></div>`;
  Object.keys(questValues.questionnaireValues).forEach((i) => {
    let newElem = question.cloneNode(true);
    let questWrap = questionWrapper.cloneNode(true);
    newElem.firstElementChild.innerHTML =
      questValues.questionnaireValues[i].question;
    questWrap.firstElementChild.append(newElem);
    Object.entries(questValues.questionnaireValues[i].answers).forEach(
      (ans) => {
        let elem = answer.cloneNode(true);
        elem.firstElementChild.innerHTML = ans[1];
        questWrap.firstElementChild.append(elem);
      }
    );
    questionnaireWrapper.append(questWrap.firstElementChild);
  });
  questValues.prizes.forEach((prize) => {
    let newElem = answerElem.cloneNode(true);
    newElem.querySelector(".item-img").src = prize.prizeImageObj;
    newElem.querySelector(".qu-prize-name").innerHTML = prize.prizeName;
    newElem.querySelector(
      ".qu-yourprice-value"
    ).innerHTML = ` $${prize.prizePrice} (DHL shipping cost)`;
    newElem.querySelector(
      ".qu-prizes-remaining"
    ).innerHTML = ` ${prize.prizeAmount} units`;
    prizesWrapper.append(newElem);
  });
  return wrapper.innerHTML;
};
