import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Upload, Space, Row, Col, Radio, ColorPicker } from 'antd';
import { PlusOutlined, UploadOutlined, DeleteOutlined } from '@ant-design/icons';
//import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { survey2SearchService } from '../services/survey2Search';
import './buttonPreviewStyles.css'
import { openErrorNotification, openSuccessNotification } from '../utils/notifications';
import TextEditor from './TextEditor';

const { Option } = Select;

const ACTION_TYPES = {
  DO_NOTHING: 'do_nothing',
  INSERT_LINK: 'insert_link',
  MOVE_TO_NEXT_QUESTION: 'move_to_next_question',
  MOVE_TO_NEXT_VERTICAL: 'move_to_next_vertical',
};

const initialAction = () => ({
  type: ACTION_TYPES.MOVE_TO_NEXT_QUESTION,
  url: '',
  targetPage: null,
});

const initialAnswer = () => ({
  actions: [initialAction(), initialAction()],
});

const initialQuestion = () => ({
  answers: [initialAnswer()],
});

const initialPage = () => ({
  headline: '',
  backgroundImage: null,
  favicon: null,
  questions: [initialQuestion()],
});

export const Survey2SearchForm = () => {
  const [form] = Form.useForm();
  const [pages, setPages] = useState([initialPage()]);
  const [waitingForTranslations, setWaitingForTranslations] = useState(false);
  const [isPreviewHovered, setIsPreviewHovered] = useState(false);
  const [addShareButtons, setAddShareButtons] = useState(false);

  const loadFormData = () => {
    const data = JSON.parse(localStorage.getItem('formData'));
    return data;
  };
  
  useEffect(() => {
    const initialValues = loadFormData();
    form.setFieldsValue(initialValues);
    setPages(loadFormData()?.pages ?? [initialPage()])
  }, [form]);

  const addPage = () => setPages([...pages, initialPage()]);

  const removePage = ({ pageIndex }) => {
    if (pages.length > 1) {
      setPages(pages.filter((_, idx) => idx !== pageIndex));
    }
  };

  const addQuestion = ({ pageIndex }) => {
    setPages(pages.map((page, idx) =>
      idx === pageIndex
        ? { ...page, questions: [...page.questions, initialQuestion()] }
        : page
    ));
  };

  const removeQuestion = ({ pageIndex, questionIndex }) => {
    setPages(pages.map((page, idx) =>
      idx === pageIndex
        ? { ...page, questions: page.questions.filter((_, qIdx) => qIdx !== questionIndex) }
        : page
    ));
  };

  const addAnswer = ({ pageIndex, questionIndex }) => {
    setPages(pages.map((page, idx) =>
      idx === pageIndex
        ? {
            ...page,
            questions: page.questions.map((question, qIdx) =>
              qIdx === questionIndex
                ? { ...question, answers: [...question.answers, initialAnswer()] }
                : question
            ),
          }
        : page
    ));
  };

  const removeAnswer = ({ pageIndex, questionIndex, answerIndex }) => {
    setPages(pages.map((page, idx) =>
      idx === pageIndex
        ? {
            ...page,
            questions: page.questions.map((question, qIdx) =>
              qIdx === questionIndex
                ? { ...question, answers: question.answers.filter((_, aIdx) => aIdx !== answerIndex) }
                : question
            ),
          }
        : page
    ));
  };

  const updateActionField = ({ pageIndex, questionIndex, answerIndex, triggerIndex, field, value }) => {
    setPages(pages.map((page, pIdx) =>
      pIdx === pageIndex
        ? {
            ...page,
            questions: page.questions.map((question, qIdx) =>
              qIdx === questionIndex
                ? {
                    ...question,
                    answers: question.answers.map((answer, aIdx) =>
                      aIdx === answerIndex
                        ? {
                            ...answer,
                            actions: answer.actions.map((action, tIdx) =>
                              tIdx === triggerIndex ? { ...action, [field]: value } : action
                            ),
                          }
                        : answer
                    ),
                  }
                : question
            ),
          }
        : page
    ));
  };

  const handleFileUpload = async (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const onGenerateHTML = async (translations) => {
    const updatedValues = form.getFieldsValue();

    for (const page of updatedValues.pages) {
      if (page.backgroundImage && page.backgroundImage.file) {
        page.backgroundImage = await handleFileUpload(page.backgroundImage.file);
      }
      if (page.favicon && page.favicon.file) {
        page.favicon = await handleFileUpload(page.favicon.file);
      }
    }

    return generateHTML(updatedValues, translations);
    
  };


  const downloadHTML = async () => {
    const html = await onGenerateHTML()

    const content = html.head+html.body

    // Logic to download the generated HTML content
    const blob = new Blob([content], { type: 'text/html' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'quiz.html';
    link.click();
  }

  const previewHTML = async () => {
    const html = await onGenerateHTML();
    const content = html.head + html.body;
    const blob = new Blob([content], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
  
    window.open(url, '_blank');
  }

  const resetForm = () => {
    localStorage.removeItem('formData');
    form.setFieldsValue({});
    form.resetFields();
  };

  const generateHTML = (values, translations) => {
    const { pages } = values;

    const head = `

          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Quiz</title>
          ${(pages && pages[0] && pages[0].favicon ? `<link rel="icon" href="${pages[0].favicon}" />` : '')}
          {{QS_WEBPUSH}}
          `
    const shareButtons = `
                    <div class="share-container">
                      <div class="share-icon">
                        <?xml version="1.0" ?><!DOCTYPE svg  PUBLIC '-//W3C//DTD SVG 1.1//EN'  'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'><svg height="22px" id="Layer_1" style="enable-background:new 0 0 64 64;" version="1.1" viewBox="0 0 64 64" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><style type="text/css">.st0{fill:#134563;}</style><g><g id="Icon-Share-Google" transform="translate(280.000000, 380.000000)"><path class="st0" d="M-264.2-339.9c-4.4,0-7.9-3.5-7.9-7.9c0-4.4,3.5-7.9,7.9-7.9c4.4,0,7.9,3.5,7.9,7.9     C-256.3-343.5-259.8-339.9-264.2-339.9L-264.2-339.9z M-264.2-352.8c-2.7,0-4.9,2.2-4.9,4.9c0,2.7,2.2,4.9,4.9,4.9     c2.7,0,4.9-2.2,4.9-4.9C-259.3-350.5-261.5-352.8-264.2-352.8L-264.2-352.8z" id="Fill-61"/><path class="st0" d="M-232.1-356c-4.4,0-7.9-3.5-7.9-7.9s3.5-7.9,7.9-7.9s7.9,3.5,7.9,7.9S-227.8-356-232.1-356     L-232.1-356z M-232.1-368.8c-2.7,0-4.9,2.2-4.9,4.9s2.2,4.9,4.9,4.9s4.9-2.2,4.9-4.9S-229.4-368.8-232.1-368.8L-232.1-368.8z" id="Fill-62"/><path class="st0" d="M-232.1-323.9c-4.4,0-7.9-3.5-7.9-7.9s3.5-7.9,7.9-7.9s7.9,3.5,7.9,7.9     S-227.8-323.9-232.1-323.9L-232.1-323.9z M-232.1-336.7c-2.7,0-4.9,2.2-4.9,4.9s2.2,4.9,4.9,4.9s4.9-2.2,4.9-4.9     S-229.4-336.7-232.1-336.7L-232.1-336.7z" id="Fill-63"/><polyline class="st0" id="Fill-64" points="-238.6,-333.2 -259.2,-343.5 -257.8,-346.4 -237.1,-336.1 -238.6,-333.2    "/><polyline class="st0" id="Fill-65" points="-257.8,-349.3 -259.2,-352.1 -238.6,-362.4 -237.1,-359.6 -257.8,-349.3    "/></g></g></svg>
                      </div>
                      <div class="share-buttons">
                        <a href="https://www.facebook.com/sharer/sharer.php?u=${window.location.href}" target="_blank">
                          <?xml version="1.0" ?><!DOCTYPE svg  PUBLIC '-//W3C//DTD SVG 1.1//EN'  'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'><svg height="32px" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;" version="1.1" viewBox="0 0 512 512" width="100%" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:serif="http://www.serif.com/" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path d="M512,256c0,-141.385 -114.615,-256 -256,-256c-141.385,0 -256,114.615 -256,256c0,127.777 93.616,233.685 216,252.89l0,-178.89l-65,0l0,-74l65,0l0,-56.4c0,-64.16 38.219,-99.6 96.695,-99.6c28.009,0 57.305,5 57.305,5l0,63l-32.281,0c-31.801,0 -41.719,19.733 -41.719,39.978l0,48.022l71,0l-11.35,74l-59.65,0l0,178.89c122.385,-19.205 216,-125.113 216,-252.89Z" style="fill:#1877f2;fill-rule:nonzero;"/><path d="M355.65,330l11.35,-74l-71,0l0,-48.022c0,-20.245 9.917,-39.978 41.719,-39.978l32.281,0l0,-63c0,0 -29.297,-5 -57.305,-5c-58.476,0 -96.695,35.44 -96.695,99.6l0,56.4l-65,0l0,74l65,0l0,178.89c13.033,2.045 26.392,3.11 40,3.11c13.608,0 26.966,-1.065 40,-3.11l0,-178.89l59.65,0Z" style="fill:#fff;fill-rule:nonzero;"/></g></svg>
                        </a>
                        <a href="https://twitter.com/intent/tweet?url=${window.location.href}&text=Check%20out%20what%20I%20found!" target="_blank">
                          <svg height="32px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>
                        </a>
                        <a href="mailto:?subject=Check%20out%20what%20I%20found!&body=${window.location.href}" target="_blank">
                           <?xml version="1.0" ?><!DOCTYPE svg  PUBLIC '-//W3C//DTD SVG 1.1//EN'  'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'><svg height="32px" width="32px" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="_x31_12-gmail_x2C__email_x2C__mail"><g><g><g><rect height="358.87" style="fill:#F1F5F7;" width="357.904" x="77.045" y="76.565"/><path d="M256.002,293.738l178.947,141.697v-279.74L256.002,293.738z M256.002,293.738" style="fill:#DCE6EA;"/><path d="M449.861,76.565h-14.912L256.002,218.26L77.045,76.565H62.134      c-24.693,0-44.737,20.094-44.737,44.858v269.152c0,24.759,20.044,44.859,44.737,44.859h14.911v-279.74l178.957,138.014      l178.947-138.047v279.773h14.912c24.699,0,44.742-20.101,44.742-44.859V121.424C494.604,96.66,474.561,76.565,449.861,76.565      L449.861,76.565z M449.861,76.565" style="fill:#F84437;"/></g></g></g></g><g id="Layer_1"/></svg>
                        </a>
                      </div>
                    </div>
                  `;


    const body =`
          ${addShareButtons ? shareButtons : ""}
          ${pages
            .map(
              (page, pageIndex) => `
            <div class="quiz-wrap" id="page-${pageIndex}" style="display: ${
                pageIndex === 0 ? 'block' : 'none'
              };">
              <style>
                #bg-${pageIndex}{
                  background-image: url('${page.backgroundImage}');
                }
              </style>
                <div class="quiz-bg" id="bg-${pageIndex}"></div>
                <div class="quiz-content">
                    ${page.headline ? `<div class="quiz-headline" id="headline-${pageIndex}">${page.headline}</div>` : ''}
                    ${page.questions
                      .map(
                        (q, qIndex) => `
                      <div class="quiz-question" id="page-${pageIndex}-question-${qIndex}" style="display: ${
                          qIndex === 0 ? 'block' : 'none'
                        };">
                        <div class="question-text">${q.question}</div>
                        <div class="quiz-buttons">
                          ${q.answers
                            .map(
                              (a, aIndex) => `
                              <div class="quiz-answer">
                                <button
                                  class="quiz-button ${a.style.variant ?? 'btn-variant-1'}"
                                  onclick="handleClick(${pageIndex}, ${qIndex}, ${aIndex})"
                                  style=
                                    "
                                      color: ${a.style.normal.color};
                                      background: ${a.style.normal.background};
                                      border-color: ${a.style.normal.border};
                                    "
                                  onmouseover= 
                                    "
                                      this.style.color = '${a.style.hover.color}'
                                      this.style.background = '${a.style.hover.background}'
                                      this.style.borderColor = '${a.style.hover.border}'
                                    "
                                  onmouseout=
                                    "
                                      this.style.color = '${a.style.normal.color}'
                                      this.style.background = '${a.style.normal.background}'
                                      this.style.borderColor = '${a.style.normal.border}'
                                    "
                                >
                                      ${a.answer}
                                </button>
                              </div>
                          `
                            )
                            .join('')}
                        </div>
                      </div>
                    `
                      )
                      .join('')}
                </div>
            </div>
          `
            )
            .join('')}
          <script>
              var clickId = '';
              var source = '';
              let searchParamsFromHref = new URLSearchParams(window.location.href);
              let sidNew = searchParamsFromHref.get("sid") || "";

              const pagesData = ${JSON.stringify(pages)};
              const translations = ${JSON.stringify(translations)};
              function handleClick(pageIndex, qIndex, aIndex) {
                  const actions = pagesData[pageIndex].questions[qIndex].answers[aIndex].actions;
                  const action = actions[0]; // onClick action
                  performAction(action, { pageIndex, qIndex });

                  const afterAction = actions[1]; // afterClick action
                  performAfterClickAction(afterAction, { pageIndex, qIndex });
              }

              function performAction(action, { pageIndex, qIndex }) {
                  if (action.type === '${ACTION_TYPES.DO_NOTHING}') {
                      return;
                  } else if (action.type === '${ACTION_TYPES.INSERT_LINK}') {
                        if (clickId) {
                        const url = new URL(action.url);
                        url.searchParams.set('cid', clickId);
                        url.searchParams.set('sid', \`\${source}__\${sidNew}\`);
                        action.url = url.toString();
                      }
                      firePX6();
                      window.open(action.url, '_blank');
                  } else if (action.type === '${ACTION_TYPES.MOVE_TO_NEXT_QUESTION}') {
                      const nextQuestionIndex = qIndex + 1;
                      if (nextQuestionIndex < pagesData[pageIndex].questions.length) {
                          document.getElementById(\`page-\${pageIndex}-question-\${qIndex}\`).style.display = 'none';
                          document.getElementById(\`page-\${pageIndex}-question-\${nextQuestionIndex}\`).style.display = 'block';
                      }
                  } else if (action.type === '${ACTION_TYPES.MOVE_TO_NEXT_VERTICAL}') {
                      const targetPageIndex = action.targetPage - 1;
                      if (targetPageIndex < pagesData.length) {
                          document.getElementById(\`page-\${pageIndex}\`).style.display = 'none';
                          document.getElementById(\`page-\${targetPageIndex}\`).style.display = 'block';
                          document.getElementById('bg-' + targetPageIndex).style.backgroundImage = document.getElementById(
                              'bg-' + targetPageIndex
                          ).style.backgroundImage;
                          document.querySelector('link[rel="icon"]').href = pagesData[targetPageIndex].favicon;
                      }
                  }
              }

              function performAfterClickAction(action, { pageIndex, qIndex }) {
                  if (action.type === '${ACTION_TYPES.INSERT_LINK}') {
                      if (clickId) {
                        const url = new URL(action.url);
                        url.searchParams.set('cid', clickId);
                        url.searchParams.set('sid', source);
                        action.url = url.toString();
                      }
                      window.location.href = action.url;
                  } else {
                      performAction(action, { pageIndex, qIndex });
                  }
              }

              function replaceContent(language) {
                const translationData = translations[language] || translations['en'];
                translationData.forEach((page, pageIndex) => {
                    if (page.headline) {
                        document.getElementById(\`headline-\${pageIndex}\`).innerHTML = page.headline;
                    }
                    page.questions.forEach((q, qIndex) => {
                        document.getElementById(\`page-\${pageIndex}-question-\${qIndex}\`).getElementsByClassName('question-text')[0].innerHTML = q.question;
                        q.answers.forEach((a, aIndex) => {
                            document.getElementById(\`page-\${pageIndex}-question-\${qIndex}\`).getElementsByClassName('quiz-button')[aIndex].innerHTML = a;
                        });
                    });
                });
            }

            function firePX6() {
                fetch(\`https://data-px.services/?click_id=\${clickId}&p=p6\`)
            }

            (function() {
                const userLang = navigator.language || navigator.userLanguage;
                const language = userLang.split('-')[0]; // Get the base language code (e.g., 'en' from 'en-US')
                replaceContent(language);
            })();
            
              (function() {
              // Function to get the value of a specific query string parameter
              function getQueryStringParameter(name) {
                  const urlParams = new URLSearchParams(qs);
                  return urlParams.get(name);
              }

              // Get the click_id from the query string
              clickId = getQueryStringParameter('click_id');
              source = getQueryStringParameter('utm_source');

              // If click_id exists, update all <a> tags
              if (clickId) {
                  const links = document.querySelectorAll('a');
                  links.forEach(link => {
                      const url = new URL(link.href);
                      url.searchParams.set('cid', clickId);
                      url.searchParams.set('sid', source);
                      link.href = url.toString();
                  });
              }
          })();
          </script>
          <style>
              ${cssStyles}
          </style>
    `;

    return {head,body}
  
  };

  const renderAnswers = ({ pageIndex, questionIndex, answers }) => {
    return answers.map((answer, aIndex) => (
      <div key={aIndex} className="answer-box" style={{ padding: '16px', background: 'rgba(255, 255, 255, 0.1)', borderRadius: '8px', marginBottom: '16px' }}>
        <Space direction="vertical" align="baseline" className="answer-container">
          <Row>
          <Col flex="auto">
                    <Form.Item
                    label={<b>Answer {aIndex + 1}</b>}
                    name={['pages', pageIndex, 'questions', questionIndex, 'answers', aIndex, 'answer']}
                    rules={[{ required: true, message: 'Answer is required' }]}
                    >
                        <TextEditor placeholder="Enter answer" />
                    </Form.Item>
                    <Form.Item
                    label={<b>Button style</b>}
                    name={['pages', pageIndex, 'questions', questionIndex, 'answers', aIndex, 'style', 'variant']}
                    >
                        <Radio.Group defaultValue="btn-variant-1">
                            <Radio value="btn-variant-1">Standard</Radio>
                            <Radio value="btn-variant-2">Standard green</Radio>
                            <Radio value="btn-variant-3">Special</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <span><b>Normal colors:</b></span><br />
                    <Row style={{justifyContent: "space-between", textAlign: 'center'}}>
                      <Form.Item getValueFromEvent={(color) => {
                          return "#" + color.toHex();
                        }} 
                        label={<b>Text</b>} name={['pages', pageIndex, 'questions', questionIndex, 'answers', aIndex, 'style', 'normal', 'color']}
                      >
                        <ColorPicker allowClear defaultValue='#ffffff' />
                      </Form.Item>
                      <Form.Item getValueFromEvent={(color) => {
                          return "#" + color.toHex();
                        }} label={<b>Background</b>} name={['pages', pageIndex, 'questions', questionIndex, 'answers', aIndex, 'style', 'normal', 'background']}>
                        <ColorPicker allowClear defaultValue='#3b3b3b' />
                      </Form.Item>
                      <Form.Item getValueFromEvent={(color) => {
                          return "#" + color.toHex();
                        }} label={<b>Border</b>} name={['pages', pageIndex, 'questions', questionIndex, 'answers', aIndex, 'style', 'normal', 'border']}>
                        <ColorPicker allowClear defaultValue='#ffffff' />
                      </Form.Item>
                    </Row>
                    <span><b>Hover colors:</b></span><br />
                    <Row style={{justifyContent: "space-between", textAlign: 'center'}}>
                      <Form.Item getValueFromEvent={(color) => {
                          return "#" + color.toHex();
                        }} 
                        label={<b>Text</b>} name={['pages', pageIndex, 'questions', questionIndex, 'answers', aIndex, 'style', 'hover', 'color']}
                      >
                        <ColorPicker allowClear defaultValue='#000000' />
                      </Form.Item>
                      <Form.Item getValueFromEvent={(color) => {
                          return "#" + color.toHex();
                        }} label={<b>Background</b>} name={['pages', pageIndex, 'questions', questionIndex, 'answers', aIndex, 'style', 'hover', 'background']}>
                        <ColorPicker allowClear defaultValue='#ececec' />
                      </Form.Item>
                      <Form.Item getValueFromEvent={(color) => {
                          return "#" + color.toHex();
                        }} label={<b>Border</b>} name={['pages', pageIndex, 'questions', questionIndex, 'answers', aIndex, 'style', 'hover', 'border']}>
                        <ColorPicker allowClear defaultValue='#ffffff' />
                      </Form.Item>
                    </Row>
                    <div style={{paddingTop: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#444', borderRadius: 4}}>
                        <Form.Item shouldUpdate={(prevValues, currentValues) => 
                            prevValues.pages[pageIndex].questions[questionIndex].answers[aIndex].style !== 
                            currentValues.pages[pageIndex].questions[questionIndex].answers[aIndex].style}>
                            {() => (
                            <button className={`${form.getFieldValue(['pages', pageIndex, 'questions', questionIndex, 'answers', aIndex, 'style', 'variant']) ?? 'btn-variant-1'}`}
                              style={!isPreviewHovered ? 
                                {
                                  color: `${form.getFieldValue(['pages', pageIndex, 'questions', questionIndex, 'answers', aIndex, 'style', 'normal', 'color'])}`,
                                  background: `${form.getFieldValue(['pages', pageIndex, 'questions', questionIndex, 'answers', aIndex, 'style', 'normal', 'background'])}`,
                                  borderColor: `${form.getFieldValue(['pages', pageIndex, 'questions', questionIndex, 'answers', aIndex, 'style', 'normal', 'border'])}`,
                                } :
                                {
                                  color: `${form.getFieldValue(['pages', pageIndex, 'questions', questionIndex, 'answers', aIndex, 'style', 'hover', 'color'])}`,
                                  background: `${form.getFieldValue(['pages', pageIndex, 'questions', questionIndex, 'answers', aIndex, 'style', 'hover', 'background'])}`,
                                  borderColor: `${form.getFieldValue(['pages', pageIndex, 'questions', questionIndex, 'answers', aIndex, 'style', 'hover', 'border'])}`,
                                }
                              }
                              onMouseEnter={() => setIsPreviewHovered(true)}
                              onMouseLeave={() => setIsPreviewHovered(false)}
                            >
                                Button
                            </button>
                        )}
                        </Form.Item>
                    </div>
                </Col>
            <Col>
              <Button
                icon={<DeleteOutlined />}
                onClick={() => removeAnswer({ pageIndex, questionIndex, answerIndex: aIndex })}
                style={{ marginLeft: '16px', marginTop: '29px', color: 'red', borderColor: 'red' }}
              />
            </Col>
          </Row>

          {renderActions({ pageIndex, questionIndex, answerIndex: aIndex, actions: answer.actions })}
        </Space>
      </div>
    ));
  };

  const renderActions = ({ pageIndex, questionIndex, answerIndex, actions }) => {
    return actions.map((action, tIdx) => (
      <Row key={tIdx} gutter={16} align="middle">
        <Col>
          <Form.Item
            label={<b>{tIdx === 0 ? 'onClick' : 'afterClick'}</b>}
            name={['pages', pageIndex, 'questions', questionIndex, 'answers', answerIndex, 'actions', tIdx, 'type']}
            initialValue={ACTION_TYPES.MOVE_TO_NEXT_QUESTION}
          >
            <Select
              onChange={(value) =>
                updateActionField({ pageIndex, questionIndex, answerIndex, triggerIndex: tIdx, field: 'type', value })
              }
              style={{ minWidth: '200px' }}
            >
              <Option value={ACTION_TYPES.INSERT_LINK}>Insert Link</Option>
              <Option value={ACTION_TYPES.DO_NOTHING}>Do Nothing</Option>
              <Option value={ACTION_TYPES.MOVE_TO_NEXT_QUESTION}>Move to Next Question</Option>
              <Option value={ACTION_TYPES.MOVE_TO_NEXT_VERTICAL}>Move to Next Vertical</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col>
          {action.type === ACTION_TYPES.INSERT_LINK && (
            <Form.Item
              label={<b>Target URL</b>}
              name={['pages', pageIndex, 'questions', questionIndex, 'answers', answerIndex, 'actions', tIdx, 'url']}
              rules={[{ required: true, message: 'URL is required' }]}
            >
              <Input
                placeholder="Enter URL"
                onChange={(e) =>
                  updateActionField({ pageIndex, questionIndex, answerIndex, triggerIndex: tIdx, field: 'url', value: e.target.value })
                }
              />
            </Form.Item>
          )}
          {action.type === ACTION_TYPES.MOVE_TO_NEXT_VERTICAL && (
            <Form.Item
              label={<b>Vertical number</b>}
              name={['pages', pageIndex, 'questions', questionIndex, 'answers', answerIndex, 'actions', tIdx, 'targetPage']}
              rules={[{ required: true, message: 'Target vertical is required' }]}
            >
              <Input
                placeholder="Vertical number"
                type="number"
                min={1}
                max={pages.length}
                onChange={(e) =>
                  updateActionField({
                    pageIndex,
                    questionIndex,
                    answerIndex,
                    triggerIndex: tIdx,
                    field: 'targetPage',
                    value: e.target.value,
                  })
                }
              />
            </Form.Item>
          )}
        </Col>
      </Row>
    ));
  };

  const renderQuestions = (page, pageIndex) => {
    return page.questions.map((question, qIndex) => (
      <div key={qIndex} className="question-container" style={{ position: 'relative', padding: '16px', border: '1px solid #ccc', borderRadius: '8px', marginBottom: '16px' }}>
        <Row>
          <Col flex="auto">
            <Form.Item
              label={<b>Question {qIndex + 1}</b>}
              name={['pages', pageIndex, 'questions', qIndex, 'question']}
              rules={[{ required: true, message: 'Question is required' }]}
            >
              <TextEditor placeholder="Enter question" />
            </Form.Item>
          </Col>
          <Col>
            {page.questions.length > 1 && (
              <Button
                // type="danger"
                icon={<DeleteOutlined />}
                onClick={() => removeQuestion({ pageIndex, questionIndex: qIndex })}
                style={{ marginLeft: '16px', marginTop: '29px', color: 'red', borderColor: 'red' }}
              />
            )}
          </Col>
        </Row>
        {renderAnswers({ pageIndex, questionIndex: qIndex, answers: question.answers })}
        <Button type="primary" onClick={() => addAnswer({ pageIndex, questionIndex: qIndex })} icon={<PlusOutlined />}>
          Add Answer
        </Button>
      </div>
    ));
  };

  //const history = useHistory();


  const onFinish = async (values) => {
    const updatedValues = { ...values };

    for (const page of updatedValues.pages) {
      if (page.backgroundImage && page.backgroundImage.file) {
        page.backgroundImage = await handleFileUpload(page.backgroundImage.file);
      }
      if (page.favicon && page.favicon.file) {
        page.favicon = await handleFileUpload(page.favicon.file);
      }
    }

    const forTranslate = updatedValues.pages.map(page => ({
          headline: page.headline,
          questions: page.questions.map(question => ({
              question: question.question,
              answers: question.answers.map(answer => answer.answer)
          }))
      }));
    
    setWaitingForTranslations(true);

    try {
      const formValues = form.getFieldsValue();
      localStorage.setItem('formData', JSON.stringify(formValues));
      openSuccessNotification({
        message: "Form values have been saved locally"
      })

      const translations = await survey2SearchService.getTranslations({verticals:forTranslate});
      const html = await onGenerateHTML(translations)

      sessionStorage.setItem('survey2Search', JSON.stringify({ html }));
      const url = `${window.location.origin}/generator/lp/${localStorage.getItem("father_template")}/new`;

      window.open(url, '_blank');

      setWaitingForTranslations(false);
    } catch (error) {
      setWaitingForTranslations(false);
      openErrorNotification({
        message: "Generating LP failed",
      });
    }
  };


  return (
    <Form form={form} initialValues={loadFormData} onFinish={onFinish} layout="vertical" style={{ maxWidth: '800px', margin: 'auto' }}>
      {pages.map((page, pageIndex) => (
        <div key={pageIndex} className="page-container">
          {/* Spacing hack */}
          <br/> 
          <Row justify="space-between" align="middle" >
            <Col>
              <h2>
                <b>Vertical {pageIndex + 1}</b>
              </h2>
            </Col>
            <Col>
              {pages.length > 1 && (
                <Button
                  // type="danger"
                  icon={<DeleteOutlined />}
                  onClick={() => removePage({ pageIndex })}
                  style={{ color: 'red', borderColor: 'red' }}
                />
              )}
            </Col>
          </Row>

          <Form.Item
            label={<b>Headline</b>}
            name={['pages', pageIndex, 'headline']}
            rules={[{ required: true, message: 'Headline is required' }]}
          >
            <TextEditor placeholder="Enter headline" />
          </Form.Item>

          <Form.Item
            label={<b>Upload Background Image</b>}
            name={['pages', pageIndex, 'backgroundImage']}
          >
            <Upload name="background" listType="picture" beforeUpload={() => false}>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            label={<b>Upload Favicon</b>}
            name={['pages', pageIndex, 'favicon']}
          >
            <Upload name="favicon" listType="picture" beforeUpload={() => false}>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>

          {renderQuestions(page, pageIndex)}

          <Button type="primary" onClick={() => addQuestion({ pageIndex })} icon={<PlusOutlined />}>
            Add Question
          </Button>
        </div>
      ))}

      <Button type="primary" onClick={addPage} icon={<PlusOutlined />} style={{ marginTop: 16 }}>
        Add Vertical
      </Button>

      <Form.Item
        style={{marginTop: 20}}
        name="add_social_icons"
      >
        <input onChange={() => setAddShareButtons(!addShareButtons)} id="share_buttons_checkbox" type="checkbox"></input>
        <label style={{marginLeft: 10}} htmlFor="share_buttons_checkbox"><b>Add share buttons</b></label>
      </Form.Item>

      <div style={{display: 'flex', gap: 10, marginTop: 26, height: 30}}>
        <Form.Item>
          <Button type="primary" onClick={downloadHTML}>
            Generate HTML
          </Button>
        </Form.Item>

        <Form.Item>
          <Button type="primary" onClick={previewHTML}>
            Preview HTML
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            icon={<DeleteOutlined />}
            onClick={resetForm}
            style={{color: 'white', backgroundColor: 'red' }}
          > Reset form </Button>
        </Form.Item>
      </div>

      <Form.Item style={{ marginTop: 16 }}>
        <Button type="primary" htmlType="submit" loading={waitingForTranslations}>
          Generate LP
        </Button>
      </Form.Item>
    </Form>
  );
};

const cssStyles = `
* {
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

body {
    min-height: 100vh;
    font-family: Panton, sans-serif;
    font-size: 28px;
    font-weight: 400;
    margin: 0;
}

a {
    color: inherit;
}

button,
input,
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    box-shadow: none;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    outline: none;
}

article,
aside,
footer,
header,
main,
nav {
    display: block;
}

.quiz-wrap {
    color: #fff;
    min-height: 100vh;
    min-width: 320px;
    overflow: hidden;
    position: relative;
}

.quiz-content {
    max-width: 990px;
    margin-left: auto;
    margin-right: auto;
    min-height: 100vh;
    padding: 90px 15px;
    position: relative;
    z-index: 2;
}

.quiz-highlight {
    color: #00e871;
    font-weight: 600;
}

.quiz-headline {
    line-height: 1.2;
    margin-bottom: 105px;
    max-width: 600px;
    padding-top: 10px;
    padding-bottom: 15px;
    position: relative;
    z-index: 1;
}

.quiz-headline:before {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 60%, transparent);
    box-sizing: border-box;
    content: "";
    height: 100%;
    left: calc(-50vw + 480px);
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: -1;
}

.quiz-question {
    max-width: 600px;
    padding-top: 30px;
    padding-bottom: 20px;
    position: relative;
    z-index: 1;
}

.quiz-question:before {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 60%, transparent);
    box-sizing: border-box;
    content: "";
    height: 100%;
    left: calc(-50vw + 480px);
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: -1;
}

.quiz-buttons {
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 550px;
    padding-top: 30px;
    flex-direction: row;
}

.btn-variant-1 {
    background-color: hsla(0, 0%, 100%, 0.2);
    border: 2px solid #fff;
    border-radius: 5px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2),
        inset 1px 1px 2px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    font-size: 22px;
    font-weight: 600;
    height: 80px;
    margin-right: 5px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    transition-property: border-color, color, background-color, box-shadow;
    transition-duration: 0.25s;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-variant-1:hover {
    background-color: #fff;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2), inset 0 0 0 rgba(0, 0, 0, 0.00001);
    color: #202020;
}

.btn-variant-2 {
    background-color: rgba(0,232,113,0.1);
    border: 2px solid #00e871;
    color: #00e871;
    border-radius: 5px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2),
        inset 1px 1px 2px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    font-size: 22px;
    font-weight: 600;
    height: 80px;
    margin-right: 5px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    transition-property: border-color, color, background-color, box-shadow;
    transition-duration: 0.25s;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-variant-2:hover {
    background-color: #00e871;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2), inset 0 0 0 rgba(0, 0, 0, 0.00001);
        color: #202020;
}

.btn-variant-3 {
    background-color: #4caf50;
    border: 0;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    width: fit-content;
}

.quiz-bg {
    background: #7b7b7c 50% no-repeat;
    background-size: cover;
    -webkit-animation: 30s linear infinite a;
    animation: 30s linear infinite imageScroll;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: calc(100vw + 500px);
}


@keyframes imageScroll {
  0% {
      left: 0;
  }

  50% {
      left: -500px;
  }

  to {
      left: 0;
  }
}

.quiz-answer {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    width: 100%;
}

.answer-box {
    padding: 16px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
}

.remove-answer-button {
    background-color: #ff4d4f;
    color: white;
    border: none;
    cursor: pointer;
    padding: 8px;
    margin-top: 8px;
    width: fit-content;
    align-self: flex-end;
}

.remove-answer-button:hover {
    background-color: #ff7875;
}

.remove-question-button {
    background-color: #ff4d4f;
    color: white;
    border: none;
    cursor: pointer;
    padding: 8px;
    margin-top: 16px;
    width: fit-content;
}

.remove-question-button:hover {
    background-color: #ff7875;
}

.button {
    background-color: #4caf50;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
}

.ql-size-small {
  font-size: 18px;
}

.ql-size-normal {
  font-size: 28px;
}

.ql-size-large {
  font-size: 36px;
}

.ql-size-huge {
  font-size: 48px;
}

.share-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 5%;
  left: calc(50% - 94px);
  transform: translateX(-50%);
  z-index: 9999;
}

.share-icon {
  padding: 5px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 32px;
  height: 32px;
  background: #f4f4f4;
}

.share-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: white;
  border-radius: 8px;
  gap: 10px;
}

.share-buttons > a {
  width: 32px;
  height: 32px;
  display: inline-block;
  transition: transform 0.3s ease-in-out;
}

.share-buttons > a:hover {
  transform: scale(1.2);
}

@media (max-width: 989px) {
    .quiz-headline:before {
        left: -15px;
        width: 1024px;
    }

    .quiz-question:before {
        left: -15px;
        width: 1024px;
    }
}

@media (max-width: 767px) {
    .quiz-headline {
        z-index: 2;
    }

    .quiz-question {
        z-index: 2;
    }
}

@media (max-width: 600px) {
    body {
        font-size: 24px;
    }

    .quiz-content {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .quiz-headline {
        margin-bottom: 60px;
    }

    .quiz-buttons {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: vertical;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 20px;
    }

    .quiz-button {
        max-width: 250px;
        width: 100%;
        margin: auto;
    }
}
`;
