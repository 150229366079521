export const buttonElement = (editor) => {
  editor.DomComponents.addType("button", {
    isComponent: (el) => {
      if (
        (el.tagName === "A" && el.className.includes("gjs-button")) ||
        el.tagName === "BUTTON"
      ) {
        const result = { type: "button" };
        // if (el.textContent) result.components = el.textContent;
        return result;
      }
    },
    model: {
      defaults: {
        tagName: "a",
        attributes: { class: "gjs-button" },
        draggable: true, // true = moze da se draguje u bilo koji element
        droppable: true, // true = mogucnost dodavanja drugi elemenata unutar komponente
        //components: `<components>`,
        animation: false,
        animationDelay: 1,
        triggerWebpush: false,
        traits: [
          "title",
          "href",
          "target",
          {
            type: "checkbox",
            name: "triggerWebpush",
            changeProp: 1,
            label: "Trigger WebPush on Click",
            valueTrue: true, // Value to assign when is checked, default: `true`
            valueFalse: false, // Value to assign when is unchecked, default: `false`
          },
          {
            type: "animations-selector",
            name: "animation",
            changeProp: 1,
            label: "Select animation",
          },
          {
            type: "number",
            label: "Animation delay(in seconds): ",
            name: "animationDelay",
            placeholder: "0-5",
            changeProp: 1,
            min: 0, // Minimum number value
            max: 5, // Maximum number value
            step: 1, // Number of steps
          },
        ],
      },
    },
    view: {
      tagName: "div", // po defaultu treba da bude isti tag kao od modela
      init() {
        this.listenTo(
          this.model,
          "change:animation change:animationDelay",
          this.onAnimationChange
        );
        this.listenTo(
          this.model,
          "change:animation change:triggerWebpush",
          this.onTriggerWebPushChange
        );
      },

      onTriggerWebPushChange(model) {
        let mdl = model.view.attr;
        if (model.get("triggerWebpush")) {
          if (mdl.href) {
            mdl.href = "";
          }
          mdl.onclick = `Hood('requestPushPermission');`;
        } else {
          mdl.href = "{{FILE_URL}}";
          mdl.onclick = "";
        }
      },
      onAnimationChange(model) {
        let previousStyle = model.getStyle();
        let currentAnimation = model.get("animation");
        let currentDelay = model.get("animationDelay");
        if (currentAnimation) {
          model.setStyle({
            ...previousStyle,
            "animation-duration": "1s",
            "animation-timing-function": "ease",
            "animation-fill-mode": "both",
          });
          switch (currentAnimation) {
            case "fade-in":
              model.setStyle({
                ...previousStyle,
                opacity: "0",
                "animation-name": "fade-in",
                "animation-delay": `${currentDelay}s`,
              });
              break;
            case "pulse":
              model.setStyle({
                ...previousStyle,
                opacity: "1",
                "animation-name": "pulse",
                "animation-delay": `${currentDelay}s`,
              });
              break;
            case "popright":
              model.setStyle({
                ...previousStyle,
                opacity: "0",
                "animation-name": "popright",
                "animation-delay": `${currentDelay}s`,
              });
              break;
            case "slideInDown":
              model.setStyle({
                ...previousStyle,
                opacity: "1",
                "animation-name": "slideInDown",
                "animation-delay": `${currentDelay}s`,
              });
              break;
            case "scaleIn":
              model.setStyle({
                ...previousStyle,
                opacity: "0",
                "animation-name": "scaleIn",
                "animation-delay": `${currentDelay}s`,
              });
              break;
            default:
          }
        } else {
          model.setStyle({
            ...previousStyle,
            opacity: "1",
            "animation-name": "none",
          });
        }
      },
    },
  });
};
