export class EventEmitter {
  subscribers = [];

  registerSubscriber(subscriber) {
    this.subscribers.push(subscriber);
  }

  unregisterSubscriber(subscriber) {
    const index = this.subscribers.indexOf(subscriber);
    if (index >= 0) {
      this.subscribers.splice(index, 1);
    }
  }

  dispatchEvent(...args) {
    // notify subscribers, but we make it async with Promise.resolve, just in case:
    Promise.resolve().then(() => {
      this.subscribers.forEach((subscriber) => {
        subscriber(...args);
      });
    });
  }
}
