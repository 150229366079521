import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Input,
  Space,
  InputNumber,
  Tooltip,
  message,
  Modal,
  List,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import styles from "./Forms.module.css";
import {
  toBase64,
  asyncForEach,
  dataURLtoFile,
} from "../../../services/helpers";
import { useHistory } from "react-router-dom";
import { questionnaireService } from "../../../services/questionnaire";

export const ThirdForm = ({ onModalCancel, questionnaireValues }) => {
  const [showFrqPrizesModal, setShow] = useState(false);
  const [frequentPrizes, setFrequentPrizes] = useState([]);
  const [triggeredImage, setTriggeredImage] = useState([]);

  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    getPrizes();
  }, [questionnaireValues]);

  const getPrizes = async () => {
    let response = await questionnaireService.getFrequentPrizes();
    setFrequentPrizes(response);
  };

  const onFinish = async ({ prizes }) => {
    const changeValues = async () => {
      if (prizes) {
        let newArr = [];
        await asyncForEach(prizes, async (el) => {
          el.prizeImageObj = await toBase64(el.prizeImageObj);
          newArr.push(el);
        });
        onModalCancel();
        history.push(
          `/generator/lp/${localStorage.getItem("father_template")}/new`,
          { questionnaireValues: { questionnaireValues, prizes: newArr } }
        );
      } else {
        message.error("Please input at least one prize.");
      }
    };
    changeValues();
  };

  const checkForImage = (key) => {
    return triggeredImage.length
      ? triggeredImage.some((el) => el.key === key)
      : false;
  };

  const showModal = () => {
    setShow(true);
  };

  const handleOk = (e) => {
    setShow(false);
  };

  const handleCancel = (e) => {
    setShow(false);
  };

  return (
    <Form form={form} name="prizes" onFinish={onFinish} autoComplete="off">
      <h2>Select prizes:</h2>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={showModal}
        style={{ marginBottom: "1rem" }}
      >
        Add frequent prize
      </Button>
      <Form.List name="prizes">
        {(fields, { add, remove }) => (
          <>
            <Modal
              title="Frequent prizes"
              visible={showFrqPrizesModal}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              <List
                itemLayout="horizontal"
                dataSource={frequentPrizes.length ? frequentPrizes : []}
                size="large"
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      onClick={(e) => {
                        let clickedElement = e.target;
                        let imageObj = "";
                        if (clickedElement.tagName === "H4")
                          imageObj = frequentPrizes.filter(
                            (el) =>
                              clickedElement.textContent.split(" ").join("") ===
                              el.title
                          );
                        else
                          imageObj = frequentPrizes.filter(
                            (el) => clickedElement.src === el.image
                          );
                        add({
                          prizeName: imageObj[0].title
                            .split(/(?=[A-Z])/)
                            .join(" "),
                          prizeImageObj: dataURLtoFile(imageObj[0].image),
                        });
                        let keyForImg = fields.length
                          ? fields[fields.length - 1].key + 1
                          : 0;
                        setTriggeredImage([
                          ...triggeredImage,
                          {
                            image: imageObj[0].image,
                            key: keyForImg,
                          },
                        ]);
                        setShow(false);
                      }}
                      style={{ cursor: "pointer" }}
                      avatar={<img src={item.image} height="50px" alt="" />}
                      title={item.title.split(/(?=[A-Z])/).join(" ")}
                    />
                  </List.Item>
                )}
              />
            </Modal>
            {fields.map((field) => (
              <Space
                key={field.key}
                style={{
                  display: "flex",
                  marginBottom: 8,
                }}
                align="center"
              >
                <Form.Item
                  {...field}
                  name={[field.name, "prizeImageObj"]}
                  fieldKey={[field.fieldKey, "first"]}
                  rules={[
                    { required: true, message: "Please add prize image." },
                  ]}
                  validateTrigger="onChange"
                  getValueFromEvent={(e) => {
                    return e.target.files[0];
                  }}
                  valuePropName="ref"
                >
                  {triggeredImage.length && checkForImage(field.key) ? (
                    <img
                      src={
                        triggeredImage.filter((el) => el.key === field.key)[0]
                          .image
                      }
                      alt=""
                      style={{ height: "100px", width: "100px" }}
                    />
                  ) : (
                    <input
                      className={styles.customImageInput}
                      type="file"
                      onChange={async (e) => {
                        e.persist();
                        let img = await toBase64(e.target.files[0]);
                        e.target.style.setProperty(
                          "--background",
                          `url("${img}")`
                        );
                      }}
                    />
                  )}
                </Form.Item>
                <Form.Item
                  {...field}
                  name={[field.name, "prizeName"]}
                  fieldKey={[field.fieldKey, "prizeName"]}
                  rules={[
                    { required: true, message: "Please add prize name." },
                  ]}
                >
                  <Input placeholder="Prize name" />
                </Form.Item>
                <Form.Item
                  {...field}
                  name={[field.name, "prizeDescription"]}
                  fieldKey={[field.fieldKey, "prizeDescription"]}
                  rules={[
                    {
                      required: true,
                      message: "Please add prize description.",
                    },
                  ]}
                >
                  <Input placeholder="Description" />
                </Form.Item>
                <Tooltip placement="bottom" title="Price">
                  <Form.Item
                    {...field}
                    name={[field.name, "prizePrice"]}
                    fieldKey={[field.fieldKey, "prizePrice"]}
                    rules={[
                      { required: true, message: "Please add prize price." },
                    ]}
                    initialValue={0}
                  >
                    <InputNumber
                      formatter={(value) =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    />
                  </Form.Item>
                </Tooltip>
                <Tooltip placement="bottom" title="Amount">
                  <Form.Item
                    {...field}
                    name={[field.name, "prizeAmount"]}
                    fieldKey={[field.fieldKey, "prizeAmount"]}
                    rules={[{ required: true, message: "Please add amount." }]}
                    initialValue={1}
                  >
                    <InputNumber min={0} max={100} step={1} />
                  </Form.Item>
                </Tooltip>
                <MinusCircleOutlined
                  onClick={() => {
                    if (triggeredImage.length) {
                      let newItems = triggeredImage.filter((el) => {
                        return fields.some((elem) => elem.key === el.key)
                          ? el
                          : "";
                      });
                      setTriggeredImage(newItems);
                    }
                    remove(field.name);
                  }}
                  style={{ transform: "translateY(-10px)" }}
                />
              </Space>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => {
                  add();
                }}
                block
                icon={<PlusOutlined />}
              >
                Add field
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Generate
        </Button>
      </Form.Item>
    </Form>
  );
};
