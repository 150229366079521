export const plugElement = (editor, res) => {
  editor.DomComponents.addType("plug", {
    // Special function which loops throught nodes in canvas and if condition is met returns
    // object which indicates that node is of that specific type.
    isComponent: (el) => {
      if (el.tagName === "DIV") {
        if (el.className && el.className.includes("plug")) {
          return { type: "plug", attributes: { class: "flash-plug" } }; //component type, eg. `text`, `image`, `video`, etc.
        }
      }
    },
    model: {
      defaults: {
        tagName: "div", //HTML tag of the component, eg. `span`. Default: `div`
        draggable: "[data-gjs-type=wrapper]", // You can also specify a query string to indentify elements,
        // eg. `'.some-class[title=Hello], [data-gjs-type=column]'` means you can drag the component only inside elements
        // containing `some-class` class and `Hello` title, and `column` components.
        droppable: false, // Indicates if it's possible to drop other components inside.
        //components: flashPlayerWindows,
        // Component's traits.
        preset: null,
        traits: [
          {
            type: "select-preset", // Type of option (trait). // If you don't specify the type, the `text` is the default one
            name: "preset", // The name of the attribute/property which this trait refers to.
            changeProp: 1, // Ability to switch to chaning the component property instead of attribute.
            label: "More plug templates: ", // The label you will see in front of trait box
            //options: res.data,
            options: res,
          },
        ],
        //style: {},
      },
    },
    view: {
      init() {
        // Call specific functions when model properties change.
        this.listenTo(this.model, "change:preset", this.onPresetChange);
      },
      onPresetChange(model) {
        // if (model.get("preset")) {
        //   const filtered = res.data.filter(
        //     (el) => el.id.toString() === model.get("preset")
        //   );
        //   if (filtered[0].content.indexOf("gj-ad-slider-content") !== -1)
        //     model.setAttributes({
        //       class: "gj-ad-slider-wrapper notification",
        //     });
        //   else if (
        //     filtered[0].content.indexOf("notif-1077-flash-notification") !==
        //     -1
        //   )
        //     model.setAttributes({
        //       class: "notif-1077-flash-notification",
        //     });
        //   else
        //     model.setAttributes({
        //       class: "flash-notification",
        //     });
        //   model.components(filtered[0].content);
        // }
        const preset = model.get("preset");
        if (preset) {
          const filtered = res.filter(
            (el) => el.id.toString() === model.get("preset")
          );
          if (filtered.length) {
            model.setAttributes({ class: `${filtered[0].title}` });
            model.components(filtered[0].content);
          }
        }
      },
    },
  });
};
