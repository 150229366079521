import React from "react";
import { Form, Input, Button, Col, message } from "antd";

import styles from "./Forms.module.css";

export const SecondForm = ({
  numOfQuestions,
  numOfAnswers,
  setQuestionnaireValue,
  changeCurrentForm,
}) => {
  const onFinish = (values) => {
    setQuestionnaireValue(values);
    changeCurrentForm(3);
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Fix values in order to continue");
  };

  const checkIfWide = numOfQuestions >= 3 && numOfAnswers >= 3;

  const generateQuestions = (noq, noa) => {
    let newArr = [];
    for (let i = 1; i <= noq; i++) {
      newArr.push(
        <Form.Item
          name={`question${i}`}
          key={i}
          className={styles.secondFormItem}
          rules={[
            {
              required: true,
              message: `Please fill all fields!`,
            },
          ]}
        >
          <p>Question {i}:</p>
          <Form.Item
            name={[`question${i}`, "question"]}
            rules={[
              {
                required: true,
                message: `Please fill Question${i}!`,
              },
            ]}
          >
            <Input
              placeholder={`Question`}
              className={styles.inputMarginBottom}
            />
          </Form.Item>
          <p>Answers:</p>
          {generateAnswers(noa, i)}
        </Form.Item>
      );
    }
    return newArr;
  };

  const generateAnswers = (num, qNum) => {
    let newArr = [];
    for (let i = 1; i <= num; i++) {
      newArr.push(
        <Form.Item
          name={[`question${qNum}`, "answers", `answer${i}`]}
          key={i * num}
          rules={[
            {
              required: true,
              message: `Please fill Answer${i}!`,
            },
          ]}
        >
          <Input
            placeholder={`Answer - ${i}`}
            className={styles.inputMarginBottom}
          ></Input>
        </Form.Item>
      );
    }
    return newArr;
  };

  return (
    <Form
      span={24}
      justify="center"
      name="secondform"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className={checkIfWide ? styles.modalFormWider : ""}
    >
      {generateQuestions(numOfQuestions, numOfAnswers)}
      <Col span={2} offset={22} style={{ gridColumn: "1/3" }}>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Next
          </Button>
        </Form.Item>
      </Col>
    </Form>
  );
};
