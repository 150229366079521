import React from "react";
import { Link } from "react-router-dom";
import styles from "../Templates.module.css";
import { Tooltip, message, Popconfirm } from "antd";
import {
  BarsOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { ClockCircleOutlined } from "@ant-design/icons";
import { dateFormater } from "../../../services/helpers";
import { callApi } from "../../../services/api";

function TemplateCard({ template, setModalQuestionnaire,setModalSurvey2Search }) {
  const [deleted, setDeleted] = useState(false);
  const checkQ =
    localStorage.getItem("current_category") === "Questionnaire" ? true : false;

  const checkS2S =
    localStorage.getItem("current_category") === "Survey 2 Search" ? true : false;
  const modalOpener = checkQ ? setModalQuestionnaire : setModalSurvey2Search


  const handleDeleteTemplate = () => {
    callApi({ url: `/templates/${template.id}`, method: "DELETE" })
      .then(() => {
        message.success("Template Successfully deleted!");
        setDeleted(true);
      })
      .catch(() =>
        message.error("Failed to delete. Please Check your permissions!")
      );
  };
  const handleEditTemplate = () => {
    // localStorage.setItem("action-type", "edit-template"); ??
    localStorage.setItem("father_template", template.id);
    // localStorage.setItem(
    //   "template",
    //   JSON.stringify({
    //     title: template.title,
    //     description: template.description,
    //     landing_type: template.landing_type,
    //     id: template.id,
    //   })
    // );
  };

  return (
    <div
      className={styles.templateCard}
      onClick={() => {
        localStorage.setItem("father_template", template.id);
      }}
      style={{ display: deleted ? "none" : "block" }}
    >
      <img
        alt="example"
        src={template.image}
        className={styles.templateCardImage}
      />
      <div className={styles.templateCardContent}>
        <Tooltip
          title={checkQ ? "Generate from this template" : "Go to LP table"}
        >
          {" "}
          {checkQ || checkS2S  ? (
            <p
              className={styles.templateCardTitle}
              style={{ cursor: "pointer" }}
              onClick={() => modalOpener(true)}
            >
              {template.title.length > 22
                ? `${template.title.slice(0, 19)}...`
                : template.title}
            </p>
          ) : (
            <Link to={`/landing-pages/${template.id}`}>
              <p className={styles.templateCardTitle}>
                {template.title.length > 22
                  ? `${template.title.slice(0, 19)}...`
                  : template.title}
              </p>
            </Link>
          )}
        </Tooltip>
        <Tooltip title={template.description}>
          <p
            className={styles.templateCardDescription}
          >
            {template.description.length > 90
              ? `${template.description.slice(0, 70)}...`
              : template.description}
          </p>
        </Tooltip>
        <p className={styles.templateCardDate}>
          <ClockCircleOutlined /> {dateFormater(template.updated_at)}
        </p>
      </div>

      <div className={styles.templateActionGroup}>
        <Link
          className={styles.linkActionBtn}
          to={`/landing-pages/${template.id}`}
        >
          <Tooltip title="Landing Pages">
            <button className={styles.actionBtnContent}>
              <BarsOutlined />
            </button>
          </Tooltip>
        </Link>
        {checkQ || checkS2S ? (
          <div
            className={styles.linkActionBtn}
            style={{ color: "#1890ff" }}
            onClick={() => modalOpener(true)}
          >
            <Tooltip title="Generate New Landing Page">
              <button className={styles.actionBtnContent}>
                <PlusOutlined />
              </button>
            </Tooltip>
          </div>
        ) : (
          <Link
            className={styles.linkActionBtn}
            to={`/generator/lp/${template.id}/new`}
          >
            <Tooltip title="New Landing Page">
              <button className={styles.actionBtnContent}>
                <PlusOutlined />
              </button>
            </Tooltip>
          </Link>
        )}

        <Link
          className={styles.linkActionBtn}
          to={`/generator/template/${template.id}/edit`}
        >
          <Tooltip title="Edit Template">
            <button
              className={styles.actionBtnContent}
              onClick={handleEditTemplate}
            >
              <EditOutlined />
            </button>
          </Tooltip>
        </Link>
        <Popconfirm
          title="Are you sure you want to permanently delete this template?"
          onConfirm={handleDeleteTemplate}
          okText="Yes"
          cancelText="No"
          icon={<DeleteOutlined style={{ color: "red" }} />}
        >
          <Tooltip title="Delete Template">
            <button className={styles.actionBtnDeleteContent}>
              <DeleteOutlined />
            </button>
          </Tooltip>
        </Popconfirm>
      </div>
    </div>
  );
}

export default TemplateCard;
