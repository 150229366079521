import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom.min";
import { useUpdate } from "react-use";
import Spinner from "./components/Spinner";

import LayoutMain from "./layout/LayoutMain";
import { Login } from "./pages/Login";
import { PageNotFound } from "./pages/PageNotFound";
import { AuthenticatedRoute, UnauthenticatedRoute } from "./routes/guards";
import { authService } from "./services/auth";
import { authState } from "./services/auth-state";

const Routes = () => {
  const forceUpdate = useUpdate();

  useEffect(() => {
    authState.registerSubscriber(forceUpdate); // by forcing rerender when loggedin state changes UnauthenticatedRoute and AuthenticatedRoute can redirect properly
    return () => authState.unregisterSubscriber(forceUpdate);
  }, [forceUpdate]);

  return (
    <Switch>
      <Route
        exact
        path="/page-not-found"
        render={({ location }) => {
          const from = location.state?.from ?? "";
          return <PageNotFound from={from} />;
        }}
      />
      <UnauthenticatedRoute exact path="/login" component={Login} />
      <AuthenticatedRoute path="/" component={LayoutMain} />
    </Switch>
  );
};
export function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function load() {
      await authService.loadLoggedInState();
      setLoading(false);
    }

    load();
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            gridColumn: "1/4",
            textAlign: "center",
          }}
        >
          {Spinner}
        </div>
      ) : (
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      )}
    </>
  );
}
