import React, { useEffect, useState } from "react";
import grapesjs from "grapesjs";
import "grapesjs/dist/css/grapes.min.css";
import "./Generator.css";
import { rootSettings } from "./rootSettings";
import { basicActionsPanel } from "./grapesSettings/panelsSettings";
import { Spin } from "antd";
import { modalElement } from "./grapesElements/modalElement";
import { notificationsElement } from "./grapesElements/notificationElement";
import { plugElement } from "./grapesElements/plugElement";
import { formElement } from "./grapesElements/formElement";
import { defHead } from "./utils/consts";
import { fixImport, generatreQuestionnareQA } from "../../services/helpers";
import { callApi } from "../../services/api";

export const Generator = ({
  id,
  darkMode,
  match,
  setCurrentTemplate,
  setCurrentLanding,
  location,
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (location.pathname.includes("/template/")) {
      localStorage.setItem("is_template", "true");
    } else {
      localStorage.setItem("is_template", "false");
    }
    const editor = grapesjs.init(rootSettings);
    let defaultHead = defHead;
    setLoading(true);
    callApi({ url: "/internal-templates" }).then((res) => {
      modalElement(
        editor,
        res.filter((el) => el.category === "modal")
      );
      notificationsElement(
        editor,
        res.filter((el) => el.category === "notification")
      );
      plugElement(
        editor,
        res.filter((el) => el.category === "plug")
      );
      formElement(
        editor,
        res.filter((el) => el.category === "cod-form")
      );
      const initialFetch = async () => {
        if (match.path.includes("lp")) {
          if (match.path.includes("new")) {
            try {
              const questionnaireValues =
                localStorage.getItem("current_category") === "Questionnaire"
                  ? location.state.questionnaireValues
                  : null;
              const res = await callApi({ url: `/templates/${id}` });
              let content = `${res.content}<style>${res.css}</style>`;
              localStorage.setItem("head-content", res.head);
              res.head
                ? localStorage.setItem("head-content", res.head)
                : localStorage.setItem("head-content", defaultHead);

              if (localStorage.getItem("current_category") === "Survey 2 Search"){

                const data = sessionStorage.getItem('survey2Search');

                if (data) {
                  const parsedData = JSON.parse(data);
                  content = parsedData.html.body

                  sessionStorage.removeItem('newPageData')
            
                  localStorage.setItem("head-content", parsedData.html.head);
                }
            

                //content = location.state.survey2Search.html.body;
                //localStorage.setItem("head-content",location.state.survey2Search.html.head)
              }
              setCurrentLanding({});
              if (localStorage.getItem("current_category") === "Questionnaire")
                editor.addComponents(
                  generatreQuestionnareQA(content, questionnaireValues)
                );
              else editor.addComponents(content);
            } catch (e) {
              console.log(e);
            } finally {
              setLoading(false);
            }
          } else if (match.path.includes("edit")) {
            try {
              const res = await callApi({ url: `/landing-pages/${id}` });
              const newContent = fixImport(res.content);
              const content = `${newContent}<style>${res.css}</style>`;
              setCurrentLanding({
                titleLand: res.title,
                landingId: res.id,
                statusLand: res.status,
                tagsLand: res.tags,
                descriptionLand: res.description,
              });
              localStorage.setItem("head-content", res.head);
              res.head
                ? localStorage.setItem("head-content", res.head)
                : localStorage.setItem("head-content", defaultHead);
              editor.addComponents(content);
            } catch (e) {
              console.log(e);
            } finally {
              setLoading(false);
            }
          }
        } else if (match.path.includes("template")) {
          if (match.path.includes("edit")) {
            try {
              const res = await callApi({ url: `/templates/${id}` });
              const content = `${res.content}<style>${res.css}</style>`;
              localStorage.setItem("head-content", res.head);
              const newTemplate = {
                title: res.title,
                description: res.description,
                landing_type: res.landing_type,
                is_adult: res.is_adult,
                id: res.id,
              };
              console.log("edit first");
              setCurrentTemplate(newTemplate);
              res.head
                ? localStorage.setItem("head-content", res.head)
                : localStorage.setItem("head-content", defaultHead);
              editor.addComponents(content);
            } catch (e) {
              console.log(e);
            } finally {
              setLoading(false);
            }
          } else {
            setLoading(false);
            setCurrentTemplate({});
            localStorage.setItem("head-content", defaultHead);
          }
        }
      };

      initialFetch();
    });
    localStorage.setItem("current", id);
    setTimeout(() => {
      editor.Panels.addPanel(basicActionsPanel);
    }, 0);
  }, [id, match.path, setCurrentLanding, setCurrentTemplate, location]);

  return (
    <Spin spinning={loading}>
      <div className="generator-wrapper">
        <div className="editor-row">
          <div className="editor-canvas">
            <div className="small-panel"></div>
            <div id="gjs"></div>
          </div>
          <div
            className="panel__right"
            style={{
              marginLeft: "40px",
              backgroundColor: darkMode ? "#1a1a2e" : "#fafafa",
              color: darkMode ? "#fafafa" : "#000",
            }}
          >
            <div
              className="panel__switcher"
              style={{
                color: darkMode ? "#666" : "#1890ff",
              }}
            ></div>
            <div className="content-container"></div>
            <div
              className="styles-container"
              style={{
                backgroundColor: darkMode ? "#1a1a2e" : "#fafafa",
                color: darkMode ? "#fafafa" : "#000",
              }}
            >
              <div className="traits-container"></div>
            </div>
            <div className="layers-container"></div>
          </div>
        </div>
      </div>
    </Spin>
  );
};
