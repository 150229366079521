import { toBase64 } from "../../../services/helpers";
export const modalHead = (editor) => {
  let pfx = editor.getConfig().stylePrefix;
  let modal = editor.Modal;
  let cmdm = editor.Commands;
  let codeViewer = editor.CodeManager.getViewer("CodeMirror").clone();
  let container = document.createElement("div");
  let btnEdit = document.createElement("button");

  codeViewer.set({
    codeName: "htmlmixed",
    readOnly: 0,
    theme: "hopscotch",
    autoBeautify: true,
    autoCloseTags: true,
    autoCloseBrackets: true,
    lineWrapping: true,
    styleActiveLine: true,
    smartIndent: true,
    indentWithTabs: true,
  });
  container.id = "head-modal";
  let btnImportImage = document.createElement("label");
  btnEdit.className = pfx + "btn-prim " + pfx + "btn-import";
  btnImportImage.className =
    pfx + "btn-prim " + pfx + "btn-import custom-save-import-button";
  btnImportImage.setAttribute("for", "favicon-upload");
  let uploadBtn = document.createElement("input");
  uploadBtn.id = "favicon-upload";
  uploadBtn.name = "favicon-upload";
  uploadBtn.type = "file";
  uploadBtn.accept = "image/png, image/jpeg";
  uploadBtn.onchange = async (e) => {
    let base64favicon = await toBase64(uploadBtn.files[0]);
    let code = codeViewer.editor.getValue();
    let newWrap = document.createElement("div");
    let newLink = document.createElement("link");
    newLink.type = base64favicon.split(";")[0].substring(5);
    newLink.rel = "icon";
    newLink.href = base64favicon;
    newWrap.innerHTML = code;
    let checkForFavicon = newWrap.querySelector(`link[rel="icon"]`);
    if (checkForFavicon) checkForFavicon.href = base64favicon;
    else newWrap.append(newLink);
    codeViewer.setContent(`<head>${newWrap.innerHTML}</head>`);
    localStorage.setItem("head-content", `${newWrap.innerHTML}`);
  };
  btnImportImage.innerHTML = `UPLOAD FAVICON`;
  btnEdit.innerHTML = "SAVE";
  btnEdit.classList.add("custom-save-import-button");
  btnEdit.onclick = function () {
    var code = codeViewer.editor.getValue();
    let elem = document.createElement("div");
    elem.innerHTML = code;
    codeViewer.setContent(code);
    localStorage.setItem("head-content", elem.innerHTML);
    modal.close();
  };

  cmdm.add("head-settings", {
    run: function (editor) {
      //sender && sender.set("active", 0);
      let viewer = codeViewer.editor;
      modal.setTitle("Edit &lt;head&gt; code:");
      if (!viewer) {
        var txtarea = document.createElement("textarea");
        container.appendChild(txtarea);
        container.appendChild(btnEdit);
        container.appendChild(btnImportImage);
        container.appendChild(uploadBtn);
        codeViewer.init(txtarea);
        viewer = codeViewer.editor;
      }
      if (!localStorage.getItem("head-content")) {
        let defaultContent = `<meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Click Here to edit your LP title</title>`;
        localStorage.setItem("head-content", defaultContent);
        codeViewer.setContent(`<head>${defaultContent}</head>`);
      } else {
        codeViewer.setContent(
          `<head>${localStorage.getItem("head-content")}</head>`
        );
      }
      modal.setContent("");
      modal.setContent(container);
      modal.open();
      viewer.refresh();
    },
  });
};
