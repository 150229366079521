import { blopSound } from "./presets/audio/blop";
import { snapSound } from "./presets/audio/snap";
import { strikeSound } from "./presets/audio/strike";

export const audioElement = editor => {
  editor.DomComponents.addType("audio", {
    isComponent: el => {
      if (el.tagName === "AUDIO") {
        return { type: "audio" };
      }
    },
    model: {
      defaults: {
        draggable: true, // true = moze da se draguje u bilo koji element
        droppable: false, // true = mogucnost dodavanja drugi elemenata unutar komponente
        resizable: false,
        removable: true,
        stylable: false,
        soundName: "sound1",
        soundMode: "delay",
        style: { display: "none" },
        traits: [
          {
            type: "select",
            label: "Sound Mode",
            name: "soundMode",
            changeProp: 1,
            options: [
              { name: "Delay", value: "delay" },
              { name: "Window Leave", value: "window-leave" },
            ],
          },
          {
            type: "select",
            label: "Select sound:",
            name: "soundName",
            changeProp: 1,
            options: [
              { name: "Blop", value: "sound1" },
              { name: "Snap", value: "sound2" },
              { name: "Strike", value: "sound3" },
            ],
          },
          {
            type: "audio-delay",
            label: "Sound delay(in seconds): ",
            name: "soundDelay",
            placeholder: "0-5",
            changeProp: 1,
            min: 0, // Minimum number value
            max: 5, // Maximum number value
            step: 1, // Number of steps
          },
        ],
      },
    },

    view: {
      tagName: "audio", // po defaultu treba da bude isti tag kao od modela
      init() {
        // Na promjenu tipa notifikacije poziva se opet render
        this.listenTo(
          this.model,
          "change:soundName change:soundMode",
          this.onSoundChange
        );
      },

      onSoundChange(model) {
        console.log(model.get("soundMode"));
        if (model.get("soundName") === "sound1") {
          model.setAttributes({
            ...model.getAttributes(),
            src: blopSound,
          });
        } else if (model.get("soundName") === "sound2") {
          model.setAttributes({
            ...model.getAttributes(),
            src: snapSound,
          });
        } else if (model.get("soundName") === "sound3") {
          model.setAttributes({
            ...model.getAttributes(),
            src: strikeSound,
          });
        }
      },
    },
  });
};
