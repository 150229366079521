export const formJS = `{{QS_WEBPUSH}}
<script type="text/javascript">
  var url = document.getElementById("countryURL").value;
  var tnx = document.getElementById("tnxLocation").value;
  var product = document.getElementById("productName").value;

  function send(e, formId) {
    if (e.target.disabled) {
      return;
    }
    e.target.setAttribute("disabled", true);
    var sParams = new URLSearchParams(qs);
    var clickID = sParams.get("click_id");
    if (formId == null) formId = 1;
    e.preventDefault();
    var list = document.getElementById("priceListId" + formId);
    var object = {
      name: document.getElementById("nameId" + formId).value,
      phone: document.getElementById("phoneId" + formId).value,
      channelId: document.getElementById("channelId").value,
      productId: document.getElementById("productId").value,
      amount: parseInt(list.options[list.selectedIndex].text.split(" ")[0]),
      price: parseFloat(list.options[list.selectedIndex].text.split(" ")[3]),
      clickId: clickID
    };
    var xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send(JSON.stringify(object));
    //get status message from API
    xhr.onreadystatechange = function () {
      if (xhr.readyState == XMLHttpRequest.DONE) {
        //document.getElementById(“messageId”).innerHTML = xhr.responseText;
        location.href = "/cod/" + tnx + ".html?name=" + document.getElementById(
          "nameId" + formId).value + "&product=" + product + "&phone=" + document
            .getElementById("phoneId" + formId).value;
      }
    };
  }
  document.addEventListener("DOMContentLoaded", function (event) {
    var recieveUrl = url + "/" + document.getElementById("productId").value;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", recieveUrl, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send();
    xhr.onreadystatechange = function () {
      if (xhr.readyState == XMLHttpRequest.DONE) {
        var numberOfForms = document.getElementById("numberOfForms")
          .value;
        for (var i = 1; i <= numberOfForms; i++) {
          JSON.parse(this.responseText).forEach((price) => {
            var option = document.createElement("option");
            option.text = price;
            var lst = document.getElementById("priceListId" + i);
            lst.add(option);
          }
          );
        }
      }
    };
    var slick = 0;
    if (innerWidth < 480) {
      if (!slick) {
        $(".slides").slick(
          {
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            swipeToSlide: true,
            infinite: true,
          }
        );
        slick = 1;
      }
    }
    $(window).resize(function () {
      if (innerWidth < 480) {
        if (!slick) {
          $(".slides").slick(
            {
              arrows: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              swipeToSlide: true,
              infinite: true,
            }
          );
          slick = 1;
        }
      }
      else {
        if (slick) {
          $(".slides").slick("unslick");
          slick = 0;
        }
      }
    }
    );
    var elem = $(".numerok");
    var count = Number(elem.eq(0).text());
    var time = setTimeout(function t() {
      count--;
      elem.text(count);
      if (count > 4) time = setTimeout(t, Random(1, 75) * 1000);
    }
      , Random(5, 10) * 1000);
  }
  );
  function validateField(e) {
    return instantValidation(document.getElementById(e.id));
  }
  function instantValidation(field) {
    var invalid = field.getAttribute("pattern") && field.value && new RegExp(
      field.getAttribute("pattern")).test(field.value);
    if (!invalid) {
      field.classList.remove("is-valid");
      field.classList.add("is-invalid");
    }
    else {
      field.classList.remove("is-invalid");
      field.classList.add("is-valid");
    }
    return invalid;
  }
  function changePriceDivText(field) {
    var list = document.getElementById(field.id);
    var div = document.getElementById(field.id + "_message");
    var amount = list.options[list.selectedIndex].text.split(" ")[0];
    var price = list.options[list.selectedIndex].text.split(" ")[3];
    if (amount == 1) div.innerText = "1 PAKOVANJE = " + price + " EUR";
    else div.innerText = amount + " PAKOVANJA = " + price + " EUR";
  }
  function Random(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
</script>`;