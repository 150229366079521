import * as htmlToImage from "html-to-image";
import download from "downloadjs";
import {
  fadeIn,
  pulse,
  popRight,
  slideInDown,
  scaleIn,
} from "../grapesElements/presets/animations";
import { alertScript, onLeaveScript } from "../../../services/helpers";
import { defaultSteps } from "../grapesElements/presets/steps";
import { stepsWindows } from "../grapesElements/presets/stepsWindows";
import { stepsExtFirefox } from "../grapesElements/presets/stepsExtFirefox";
import { chromeSteps } from "../grapesElements/presets/chromeSteps";
import { edgeSteps } from "../grapesElements/presets/edgeSteps";
import { message } from "antd";
import { ddlScript } from "../utils/consts";
import { callApi } from "../../../services/api";
import { getCustomLpScript, getLoopHoodAlert } from "../utils/helpers";

const FieldIds = {
  selectSnippet: "select-snippet",
  smartLinkBlock: "smart-link-block",
  smartLinkAllow: "smart-link-allow",
};

const SelectSnippetOptionValues = {
  hoodWebpushNoSmartlinks: `
    {{QS_WEBPUSH}}
    <script>
      {{SNIPPET_24}}
      {{SNIPPET_27}}
    </script>
  `,
  hoodWebpushWithSmartlinks: `
    {{QS_WEBPUSH}}
    <script>
      {{SNIPPET_24}}
      {{SNIPPET_28}}
    </script>
  `,
  loopHoodWebpush: `
    {{QS_WEBPUSH}}
    {{LOOP_URI}}
    ${getLoopHoodAlert()}
    <script>
      {{SNIPPET_24}}
      {{SNIPPET_25}}
    </script>
  `,
  loopHoodWebpushOnClick: `
    {{QS_WEBPUSH}}
    {{LOOP_URI}}
    ${getLoopHoodAlert()}
    <script>
      {{SNIPPET_24}}
      {{SNIPPET_31}}
    </script>
  `,
  customLp: "customLp",
  wpMonetization: `
    {{QS_WEBPUSH}}
    {{OPC_URI}}
    <script>
      {{SNIPPET_47}}
    </script>
  `,
};

export const defaultCommands = (editor) => {
  //////////////////////////////////PANEL SWITCHER DEFAULT COMMANDS/////////////////////////////////////
  const defaultType = editor.DomComponents.getType("textnode");
  editor.DomComponents.addType("textnode", {
    model: defaultType.model.extend({
      toHTML: function () {
        if (this.parent() && "script" === this.parent().attributes.type) {
          return this.get("content");
        } else {
          return this.get("content")
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
        }
      },
    }),
    view: defaultType.view,
    isComponent: defaultType.isComponent,
  });
  editor.Commands.add("show-content", {
    getRowEl(editor) {
      return editor.getContainer().closest(".editor-row");
    },
    getLayersEl(row) {
      return row.querySelector(".content-container");
    },

    run(editor, sender) {
      const lmEl = this.getLayersEl(this.getRowEl(editor));
      lmEl.style.display = "";
    },
    stop(editor, sender) {
      const lmEl = this.getLayersEl(this.getRowEl(editor));
      lmEl.style.display = "none";
    },
  });

  callApi({ url: "/frequent-icons" }).then((res) => {
    let newArr = [];
    res.forEach((el) => newArr.push({ name: el.name, src: el.image }));
    editor.AssetManager.add(newArr);
  });

  editor.AssetManager.add();

  editor.Commands.add("show-styles", {
    getRowEl(editor) {
      return editor.getContainer().closest(".editor-row");
    },
    getStyleEl(row) {
      return row.querySelector(".styles-container");
    },

    run(editor, sender) {
      const smEl = this.getStyleEl(this.getRowEl(editor));
      smEl.style.display = "";
    },
    stop(editor, sender) {
      const smEl = this.getStyleEl(this.getRowEl(editor));
      smEl.style.display = "none";
    },
  });
  editor.Commands.add("show-layers", {
    getRowEl(editor) {
      return editor.getContainer().closest(".editor-row");
    },
    getStyleEl(row) {
      return row.querySelector(".layers-container");
    },

    run(editor, sender) {
      const smEl = this.getStyleEl(this.getRowEl(editor));
      smEl.style.display = "";
    },
    stop(editor, sender) {
      const smEl = this.getStyleEl(this.getRowEl(editor));
      smEl.style.display = "none";
    },
  });

  //////////////////////////////////CHANGE DEVICE COMMANDS/////////////////////////////////////

  editor.Commands.add("set-device-desktop", {
    run: (editor) => editor.setDevice("Desktop"),
  });
  editor.Commands.add("set-device-mobile", {
    run: (editor) => editor.setDevice("Mobile"),
  });

  //////////////////////////////////SCREENSHOT/////////////////////////////////////

  editor.Commands.add("screenshot", {
    run: (editor) => {
      htmlToImage
        .toPng(editor.Canvas.getWrapperEl())
        .then((dataUrl) => {
          download(
            dataUrl,
            `ScreenshotFrom_${localStorage.getItem("father_template")}.png`
          );
        })
        .catch((error) => {
          console.error("oops, something went wrong!", error);
        });
    },
  });

  //////////////////////////////////////////////CUSTOM RTE///////////////////////////////////////////////////////
  editor.RichTextEditor.add("orderedList", {
    icon: '<i class="fa fa-list-ol"></i>',
    attributes: { title: "Ordered List" },
    result: (rte) => rte.exec("insertOrderedList"),
  });
  editor.RichTextEditor.add("unorderedList", {
    icon: '<i class="fa fa-list-ul"></i>',
    attributes: { title: "Unordered List" },
    result: (rte) => rte.exec("insertUnorderedList"),
  });

  editor.RichTextEditor.add("inserText", {
    name: "insertHTML",
    icon: '<i class="fa fa-paste"></i>',
    content: "",
    attributes: { title: "Paste raw text", "data-tooltip": "Paste raw text" },
    result: (rte) => {
      navigator.clipboard.readText().then((text) => {
        rte.exec("insertHTML", text);
      });
    },
  });

  editor.RichTextEditor.add("subscriptText", {
    icon: '<i class="fa fa-subscript"></i>',
    attributes: { title: "Subscript" },
    result: (rte) => rte.exec("subscript"),
  });
  editor.RichTextEditor.add("superscriptText", {
    icon: '<i class="fa fa-superscript"></i>',
    attributes: { title: "Superscript" },
    result: (rte) => rte.exec("superscript"),
  });

  editor.RichTextEditor.add("changeColour", {
    icon: `<input type="color" class="gjs-field">`,
    event: "input",
    result: (rte, action) => {
      rte.exec("foreColor", action.btn.firstChild.value);
    },
  });
  editor.RichTextEditor.add("font-size", {
    icon: `<i class="fa fa-font"></i>: <select class="gjs-field">
          <option value="1">Smallest</option>
          <option value="2">Small</option>
          <option value="3">Normal</option>
          <option value="4">Medium</option>
          <option value="5">Big</option>
          <option value="6">Bigger</option>
          <option value="7">Huge</option>
        </select>`,
    event: "change",
    result: (rte, action) => {
      rte.exec(
        "fontSize",
        action.btn.firstElementChild.nextElementSibling.value
      );
    },
  });

  //////////////////////////////////PRESET(TEMPLATE) SELECT OPTION/////////////////////////////////////

  editor.TraitManager.addType("select-preset", {
    eventCapture: ["click"],
    createInput({ trait }) {
      const traitOpts = trait.get("options") || [];
      const options = traitOpts.length ? traitOpts : [];
      const newEl = document.createElement("div");

      newEl.classList.add("selection-wrapper");
      options.forEach(
        (option) =>
          (newEl.innerHTML += `<div class="presets-image-box" id=${option.id} style="background-image:url('${option.image}')"></div>`)
      );
      return newEl;
    },
    noLabel: true,
    templateInput: `
    More templates:
    <div data-input></div>
  `,
    onEvent({ elInput, component, event }) {
      let changedPreset = null;
      changedPreset = event.target.id;
      component.set({ preset: changedPreset });
    },
  });

  //////////////////////////////////CUSTOM POSITIONING FOR NOTIFICATIONS/////////////////////////////////////

  editor.TraitManager.addType("custom-positioning", {
    eventCapture: ["click"],
    createInput({ trait }) {
      const traitOpts = trait.get("options") || [];
      const options = traitOpts.length
        ? traitOpts
        : [
          { value: "top-left", name: "Top-Left" },
          { value: "top-right", name: "Top-Right" },
          { value: "bottom-left", name: "Bottom-Left" },
          { value: "bottom-right", name: "Bottom-Right" },
        ];
      const newEl = document.createElement("div");
      newEl.classList.add("positioning-wrapper");
      options.forEach(
        (option) =>
        (newEl.innerHTML += `<div class="positioning-icon-box" id=${option.value
          }><img src=${process.env.PUBLIC_URL + "/resources/" + option.value + ".svg"
          } alt=""/></div>`)
      );
      return newEl;
    },
    noLabel: true,
    templateInput: `
    Select position:
    <div data-input></div>
  `,
    onEvent({ elInput, component, event }) {
      let changedPosition = null;
      changedPosition = event.target.parentNode.id;
      component.set({ customPosition: changedPosition });
    },
  });

  //////////////////////////////////OVERLAY OPACITY SLIDER/////////////////////////////////////

  editor.TraitManager.addType("overlay-slider", {
    eventCapture: ["change"],
    createInput({ trait }) {
      const traitMin = trait.get("min") || [];
      const traitMax = trait.get("max") || [];
      const traitStep = trait.get("step") || [];

      const newEl = document.createElement("div");
      newEl.classList.add("overlay-wrapper");
      newEl.innerHTML = `<input type="range" id="overlay-slider" name="overlay-slider" min=${traitMin} max=${traitMax} step=${traitStep}>`;
      return newEl;
    },
    noLabel: true,
    templateInput: `
    Select overlay darkness:
    <div data-input></div>
  `,
    onEvent({ elInput, component, event }) {
      let overlaySlider = null;
      overlaySlider = event.target.value;
      component.set({ overlaySlider });
    },
  });

  //////////////////////////////////ANIMATIONS/////////////////////////////////////

  editor.TraitManager.addType("animations-selector", {
    eventCapture: ["change"],
    createInput({ trait }) {
      const traitOpts = trait.get("options") || [];
      const checkForAnimation = trait.target.attributes.animation;
      const options = traitOpts.length
        ? traitOpts
        : [
          { value: "false", name: "No Animation" },
          { value: "fade-in", name: "Fade In" },
          { value: "pulse", name: "Pulse" },
          { value: "popright", name: "Pop In Right" },
          { value: "slideInDown", name: "Slide in Down" },
          { value: "scaleIn", name: "Scale In" },
        ];
      const newEl = document.createElement("div");
      newEl.innerHTML = `<select class="href-next__type">
      ${options
          .map(
            (opt) =>
              `<option value="${opt.value}" ${opt.value === checkForAnimation ? "selected" : ""
              }>${opt.name}</option>`
          )
          .join("")}
    </select>`;

      return newEl;
    },
    onEvent({ elInput, component, event }) {
      if (component.get("animation") !== event.target.value) {
        let animation = null;
        animation = event.target.value;
        let editorStyle = editor.getCss();
        if (!editorStyle.includes(animation))
          switch (animation) {
            case "fade-in":
              editor.setStyle(`${editorStyle} ${fadeIn}`);
              break;
            case "popright":
              editor.setStyle(`${editorStyle} ${popRight}`);
              break;
            case "pulse":
              editor.setStyle(`${editorStyle} ${pulse}`);
              break;
            case "slideInDown":
              editor.setStyle(`${editorStyle} ${slideInDown}`);
              break;
            case "scaleIn":
              editor.setStyle(`${editorStyle} ${scaleIn}`);
              break;
            default:
          }
        component.set({ animation });
      }
    },
  });

  //////////////////////////////////ADDING CSS TO ID (PRIVATE PROPS)/////////////////////////////////////

  editor.SelectorManager.getAll().each((selector) =>
    selector.set("private", 1)
  );

  editor.on("selector:add", (selector) => selector.set("private", 1));

  //////////////////////////////////ASSETS SETTINGS/////////////////////////////////////

  const am = editor.AssetManager;
  const assets = am.getAll();
  am.render(assets.filter((asset) => asset.get("category") === "icons"));
  // am.getContainer().insertAdjacentHTML(
  //   "afterbegin",
  //   '<div><button type="button">Click</button></div>'
  // );

  //////////////////////////////////ADDING FREE-MOVE BUTTON TO TOOLBAR/////////////////////////////////////

  editor.Commands.add("tlb-dmode", {
    run(editor) {
      let el = editor.getSelected();
      if (el && el.attributes.dmode === "") {
        el.set("dmode", "absolute");
      } else if (el && el.attributes.dmode === "absolute") {
        el.set("dmode", "");
        el.setStyle({ ...el.getStyle(), position: "static" });
      }
    },
  });

  const edc = editor.DomComponents;

  edc.getTypes().map((type) => {
    let tb = [];
    let mdl = edc.getType(type.id).model.prototype.defaults;
    tb.push({
      attributes: { class: "fa fa-arrow-up" },
      command: (ed) => ed.runCommand("core:component-exit", { force: 1 }),
    });
    if (mdl.draggable) {
      tb.push({
        attributes: {
          class: `fa fa-arrows gjs-no-touch-actions`,
          draggable: true,
        },
        //events: hasDnd(this.em) ? { dragstart: 'execCommand' } : '',
        command: "tlb-move",
      });
    }
    if (mdl.copyable) {
      tb.push({
        attributes: { class: "fa fa-clone" },
        command: "tlb-clone",
      });
    }
    if (mdl.removable) {
      tb.push({
        attributes: { class: "fa fa-trash-o" },
        command: "tlb-delete",
      });
    }
    tb.push({
      attributes: {
        class: "fa fa-cog",
      },
      command: "tlb-dmode",
    });
    mdl.toolbar = tb;
    return "";
  });

  /////////////////////////////////////BODY TRAITS/////////////////////////////////////////////////

  editor.getWrapper().set("traits", [
    {
      type: "custom-alert",
      label: "Alert: ",
      name: "alert",
      alertValue: false,
      alertText: "Random text...",
      alertDelay: 1, // Value to assign when is checked, default: `true`
      onLeave: false,
      onLeaveText: "On Leave text...",
    },
  ]);

  editor.TraitManager.addType("custom-alert", {
    eventCapture: ["change", "input"],
    createInput({ trait }) {
      const traitText = trait.get("alertText");
      const alertDelay = trait.get("alertDelay");
      const onLeaveText = trait.get("onLeaveText");
      const newEl = document.createElement("div");
      const checkForFPSelected = checkForElements("steps-script-fp");
      const checkForEXTSelected = checkForElements("steps-script-extension");
      const checkForSteps = (stepID) => {
        if (checkForFPSelected.length)
          return checkForFPSelected[0].view.el.innerHTML.indexOf(stepID) !== -1
            ? true
            : false;
        else if (checkForEXTSelected.length)
          return checkForEXTSelected[0].view.el.innerHTML.indexOf(stepID) !== -1
            ? true
            : false;
      };
      newEl.classList.add("alert-wrapper");
      newEl.innerHTML = `<label for="alert-text">Alert text:</label><input type="text" value="${traitText}" placeholder="Please input text to be shown on alert." id="alert-text" required>
      <label for="alert-delay">Alert delay (in seconds):</label><input type="number" min=0 max=10 value="${alertDelay}" id="alert-delay">
      <label for="alert-checkbox">Enable "On Entry" alert:</label><input type="checkbox" name="alert-checkbox" id="alert-check">
      <h3 style="grid-column: 1/3; color: inherit;">ON LEAVE SETTINGS</h3>
      <label for="onleave-text">Text on page leave:</label><input type="text" value="${onLeaveText}" id="onleave-text" required>
      <label for="onleave-checkbox">Enable "On Exit" alert:</label><input type="checkbox" name="onleave-checkbox" id="onleave-checkbox">`;
      if (localStorage.getItem("is_template") === "false") {
        newEl.innerHTML =
          newEl.innerHTML +
          `<h3 style="grid-column: 1/3;">STEPS SETTINGS</h3>
        <label for="steps-checkbox">Enable Flash Player steps:</label>
        <div class="opts-container">
          <input onchange="(() => {
            document.getElementById('extensions-checkbox').checked = false;
            document.getElementById('ddl-checkbox').checked = false;
          })()" type="checkbox" name="steps-checkbox" id="steps-check" ${checkForFPSelected.length ? "checked" : ""
          }>
          <div class="fp-radio-collection" style="${checkForFPSelected.length
            ? "display: inline-block;"
            : "display: none;"
          }"><input type="radio" id="windows-step" name="flash-player-step" value="windows" ${checkForSteps("DirectionsModal") ? "checked" : ""
          }>
          <label for="windows-step">Windows</label>
          <input type="radio" id="mac-step" name="flash-player-step" value="mac" ${checkForSteps("brw1") ? "checked" : ""
          }>
          <label for="mac-step">MAC</label></div>
        </div>
        <label for="extensions-checkbox">Enable Extensions steps:</label>
        <div class="opts-container"><input onchange="(() => {
          document.getElementById('steps-check').checked = false;
          document.getElementById('ddl-checkbox').checked = false;
        })()" type="checkbox" name="extensions-checkbox" id="extensions-checkbox" ${checkForEXTSelected.length ? "checked" : ""
          }>
        <div class="extensions-radio-collection"style="${checkForEXTSelected.length
            ? "display: inline-block;"
            : "display: none;"
          }">
        <input type="radio" id="chrome-step" name="extensions-steps" value="chrome" ${checkForSteps("notify-chrome") ? "checked" : ""
          }>
        <label for="chrome-step">Chrome</label>
        <input type="radio" id="edge-step" name="extensions-steps" value="edge" ${checkForSteps("notify-edge") ? "checked" : ""
          }>
        <label for="edge-step">Edge</label>
        <input type="radio" id="firefox-step" name="extensions-steps" value="firefox" ${checkForSteps("firefox-extension-overlay") ? "checked" : ""
          }>
        <label for="firefox-step">Firefox</label>
        </div></div>
        <label for="ddl-checkbox">Enable IT-DDL steps:</label>
        <input onchange="(() => {
          document.getElementById('steps-check').checked = false;
          document.getElementById('extensions-checkbox').checked = false;
        })()" type="checkbox" name="ddl-checkbox" id="ddl-checkbox">
        `;
      }

      return newEl;
    },
    noLabel: true,
    templateInput: `
    <h3 style="color: inherit;">ALERT SETTINGS</h3><div data-input></div>
  `,
    onEvent({ elInput, component, event }) {
      let ddlValue = elInput.querySelector("#ddl-checkbox").checked;
      let alertText = elInput.querySelector("#alert-text").value;
      let alertValue = elInput.querySelector("#alert-check").checked;
      let stepsValue = elInput.querySelector("#steps-check").checked;
      let extValue = elInput.querySelector("#extensions-checkbox").checked;
      let alertDelay = elInput.querySelector("#alert-delay").value;
      let onLeaveValue = elInput.querySelector("#onleave-checkbox").checked;
      let onLeaveText = elInput.querySelector("#onleave-text").value;
      let fpStepsType = elInput.querySelector(
        'input[name="flash-player-step"]:checked'
      );
      let extStepsType = elInput.querySelector(
        'input[name="extensions-steps"]:checked'
      );
      const getAllModels = () => {
        return editor.getComponents().models;
      };
      const checkForElements = (elemId) => {
        return getAllModels().filter(
          (item) => item.ccid.indexOf(elemId) !== -1
        );
      };
      const removeItems = (...args) => {
        [...args].forEach((elem) => {
          getAllModels().filter((item) =>
            item.ccid.indexOf(elem) !== -1
              ? editor.getComponents().remove(item)
              : ""
          );
        });
      };

      [alertText, alertValue, alertDelay, onLeaveValue, onLeaveText].forEach(
        (item) => component.set(item)
      );
      if (alertValue) {
        let checkForAlertScript = checkForElements("alert-script");
        if (checkForAlertScript.length < 1)
          editor.addComponents(
            `<script id="alert-script">${alertScript(
              alertValue,
              alertText,
              alertDelay
            )}</script>`
          );
        else
          checkForAlertScript[0].set(
            "content",
            `${alertScript(alertValue, alertText, alertDelay)}`
          );
      } else {
        let checkForAlert = checkForElements("alert-script");
        if (checkForAlert.length > 0) {
          checkForAlert[0].set("content", "");
        }
      }
      if (onLeaveValue) {
        let checkForOnLeaveScript = checkForElements("onleave-script");
        if (checkForOnLeaveScript.length < 1)
          editor.addComponents(
            `<script id="onleave-script">${onLeaveScript(
              onLeaveValue,
              onLeaveText
            )}</script>`
          );
        else
          checkForOnLeaveScript[0].set(
            "content",
            `${onLeaveScript(onLeaveValue, onLeaveText)}`
          );
      } else {
        let checkForOnLeave = checkForElements("onleave-script");
        if (checkForOnLeave.length > 0) {
          checkForOnLeave[0].set("content", "");
        }
      }

      if (stepsValue) {
        elInput.querySelector(".fp-radio-collection").style.display =
          "inline-block";
        let links = component.find("a.download_link");
        links.filter(
          (item) => (item.attributes.attributes.href = "{{FILE_URL}}")
        );
        if (fpStepsType) {
          if (fpStepsType.value === "windows") {
            if (checkForElements("DirectionsModal").length === 0) {
              removeItems("steps-script-fp", "brw", "brw1", "uaparser-script");
              editor.addComponents(stepsWindows);
            }
          } else if (fpStepsType.value === "mac") {
            if (checkForElements("brw").length === 0) {
              removeItems(
                "DirectionsModal",
                "steps-script-fp",
                "overlay",
                "DirectionsModal_ff",
                "DirectionsModal_ie",
                "uaparser-script"
              );
              editor.addComponents(defaultSteps);
            }
          } else {
            removeItems(
              "steps-script-fp",
              "brw",
              "brw1",
              "overlay",
              "DirectionsModal",
              "DirectionsModal_ff",
              "DirectionsModal_ie",
              "uaparser-script"
            );
          }
        }
      } else {
        elInput.querySelector(".fp-radio-collection").style.display = "none";
        removeItems(
          "steps-script-fp",
          "brw",
          "brw1",
          "overlay",
          "uaparser-script",
          "DirectionsModal",
          "DirectionsModal_ff",
          "DirectionsModal_ie"
        );
      }
      if (extValue) {
        elInput.querySelector(".extensions-radio-collection").style.display =
          "inline-block";
        if (extStepsType) {
          if (extStepsType.value === "chrome") {
            if (checkForElements("notify-chrome").length === 0) {
              removeItems(
                "jquery",
                "jquery2",
                "steps-script-extension",
                "notify-edge",
                "firefox-extension-overlay"
              );
              editor.addComponents(chromeSteps("chrome"));
            }
          } else if (extStepsType.value === "edge") {
            if (checkForElements("notify-edge").length === 0) {
              removeItems(
                "jquery",
                "jquery2",
                "steps-script-extension",
                "notify-chrome",
                "firefox-extension-overlay"
              );
              editor.addComponents(edgeSteps);
            }
          } else if (extStepsType.value === "firefox") {
            if (checkForElements("firefox-extension-overlay").length === 0) {
              removeItems(
                "jquery",
                "jquery2",
                "steps-script-extension",

                "notify-chrome",
                "notify-edge"
              );
              editor.addComponents(stepsExtFirefox);
            }
          }
        } else {
          removeItems(
            "firefox-extension-overlay",
            "jquery",
            "jquery2",
            "steps-script-extension",
            "notify-chrome",
            "notify-edge"
          );
        }
      } else {
        elInput.querySelector(".extensions-radio-collection").style.display =
          "none";
        removeItems(
          "firefox-extension-overlay",
          "jquery",
          "jquery2",
          "steps-script-extension",
          "notify-chrome",
          "notify-edge"
        );
      }
      let links = component.find("a.download_link");
      if (ddlValue) {
        if (checkForElements("ddl-script").length === 0)
          editor.addComponents(ddlScript);

        links.filter(
          (item) =>
            (item.attributes.attributes.href = "{{DIRECT_DOWNLOAD_URL}}")
        );

        let headContent = localStorage.getItem("head-content");
        if (!headContent.includes("{{SNIPPET_5}}"))
          localStorage.setItem("head-content", `${headContent} {{SNIPPET_5}}`);
      } else if (!ddlValue) {
        let headContent = localStorage.getItem("head-content");
        localStorage.setItem(
          "head-content",
          headContent.replace("{{SNIPPET_5}}", "")
        );
        removeItems("ddl-script");
        links.filter(
          (item) => (item.attributes.attributes.href = "{{FILE_URL}}")
        );
      }
    },
  });

  //////////////////////////////////HELPER FUNCTIONS/////////////////////////////////////

  const getAllModels = () => {
    return editor.getComponents().models;
  };
  const checkForElements = (elemId) => {
    return getAllModels().filter((item) => item.ccid.indexOf(elemId) !== -1);
  };

  //////////////////////////////////ADDING AUDIO SCRIPT/////////////////////////////////////

  editor.TraitManager.addType("audio-delay", {
    eventCapture: ["change", "input"],
    createInput({ trait }) {
      const traitPh = trait.get("placeholder");
      const traitMin = trait.get("min");
      const traitMax = trait.get("max");
      const traitStep = trait.get("step");

      const newEl = document.createElement("div");
      newEl.classList.add("delay-wrapper");
      newEl.innerHTML = `<input type="number" min=${traitMin} min=${traitMax} placeholder=${traitPh} step=${traitStep}  id="alert-delay-input">`;
      return newEl;
      //<label for="alert-delay-input">Audio Delay</label>
    },
    onEvent({ elInput, component, event }) {
      let soundMode = component.get("soundMode");
      let soundDelay = elInput.querySelector("#alert-delay-input").value;
      if (checkForElements(`audio-script-${component.ccid}`).length === 0) {
        editor.addComponents(`<script id="audio-script-${component.ccid}">let soundDelay = ${soundDelay};
        let soundMode = "${soundMode}" ;
        if (soundMode === "delay") {
          if (soundDelay >= 0) {
            setTimeout(() => {
              document.querySelector("#${component.ccid}").play();
            }, soundDelay * 1000);
          }
        } else {
          document.addEventListener("click", function(event) { 
            if(event.clientY <= 0 || event.clientX <= 0 || (event.clientX >= window.innerWidth || event.clientY >= window.innerHeight)){
              setTimeout(() => {
                document.querySelector("#${component.ccid}").play();
              }, soundDelay * 1000);
            }
           });
        }</script>`);
      } else {
        checkForElements(`audio-script-${component.ccid}`)[0].set(
          "content",
          `let soundDelay = ${soundDelay};
        let soundMode = "${soundMode}" ;
        if (soundMode === "delay") {
          if (soundDelay >= 0) {
            setTimeout(() => {
              document.querySelector("#${component.ccid}").play();
            }, soundDelay * 1000);
          }
        } else {
          document.addEventListener("mouseleave", function(event) { 
            if(event.clientY <= 0 || event.clientX <= 0 || (event.clientX >= window.innerWidth || event.clientY >= window.innerHeight)){
              setTimeout(() => {
                document.querySelector("#${component.ccid}").play();
              }, soundDelay * 1000);
            }
           });
        }`
        );
      }
    },
  });
  editor.DomComponents.getTypes().filter((item) => {
    if (item.id === "audio") {
      item.set("traits", [
        {
          type: "audio-settings",
          label: "Audio Mode",
          name: "audio",
          audioMode: "delay",
          audioDelay: 0,
          audioName: "sound1",
        },
      ]);
    }
    return true;
  });

  editor.TraitManager.addType("audio-settings", {
    eventCapture: ["change", "input"],
    createInput({ trait }) {
      // const audioMode = trait.get("audioMode");
      const audioDelay = trait.get("audioDelay");
      // const audioName = trait.get("audioName");
      const newEl = document.createElement("div");
      newEl.classList.add("sound-wrapper");
      newEl.innerHTML = `<label for="audio-mode">Audio Mode:</label><select name="audio-mode" id="audio-mode"><option value="delay" selected>Delay</option><option value="on-exit">On Exit</option></select><label for="audio-name">Audio Name:</label><select name="audio-name" id="audio-name"><option value="sound1" selected>Blop</option><option value="sound2">Snap</option><option value="sound3">Strike</option></select><label for="audio-delay">Audio delay (in seconds):</label><input type="number"min="0"max="10"value="${audioDelay}"id="audio-delay"/>`;

      return newEl;
    },
    noLabel: true,
    templateInput: `
    <h3 style="color: inherit;">Audio Settings</h3><div data-input></div>
  `,
    onEvent({ elInput, component, event }) {
      let alertText = elInput.querySelector("#alert-text").value;
      let alertValue = elInput.querySelector("#alert-check").checked;
      let alertDelay = elInput.querySelector("#alert-delay").value;
      let onLeaveValue = elInput.querySelector("#onleave-checkbox").checked;
      let onLeaveText = elInput.querySelector("#onleave-text").value;
      component.set({ alertText });
      component.set({ alertValue });
      component.set({ alertDelay });
      component.set({ onLeaveValue });
      component.set({ onLeaveText });
      if (alertValue) {
        let checkForAlertScript = editor
          .getComponents()
          .models.filter((item) => item.ccid === "alert-script");
        if (checkForAlertScript.length < 1)
          editor.addComponents(
            `<script id="alert-script">${alertScript(
              alertValue,
              alertText,
              alertDelay
            )}</script>`
          );
        else
          checkForAlertScript[0].set(
            "content",
            `${alertScript(alertValue, alertText, alertDelay)}`
          );
      } else {
        let checkForAlert = editor
          .getComponents()
          .models.filter((item) => item.ccid === "alert-script");
        if (checkForAlert.length > 0) {
          checkForAlert[0].set("content", "");
        }
      }
      if (onLeaveValue) {
        let checkForOnLeaveScript = editor
          .getComponents()
          .models.filter((item) => item.ccid === "onleave-script");
        if (checkForOnLeaveScript.length < 1)
          editor.addComponents(
            `<script id="onleave-script">${onLeaveScript(
              onLeaveValue,
              onLeaveText
            )}</script>`
          );
        else
          checkForOnLeaveScript[0].set(
            "content",
            `${onLeaveScript(onLeaveValue, onLeaveText)}`
          );
      } else {
        let checkForOnLeave = editor
          .getComponents()
          .models.filter((item) => item.ccid === "onleave-script");
        if (checkForOnLeave.length > 0) {
          checkForOnLeave[0].set("content", "");
        }
      }
    },
  });

  //////////////////////////////////ADDING SNIPPETS TO BODY/////////////////////////////////////
  if (localStorage.getItem("is_template") === "false") {
    editor.getWrapper().addTrait({
      type: "select-trait",
      label: "Select snippet:",
      name: "snippetName",
      changeProp: 1,
    });

    editor.TraitManager.addType("select-trait", {
      eventCapture: ["change"],
      createInput({ trait }) {
        const newEl = document.createElement("div");
        const checkForSelected = checkForElements("snippet");
        const checkForSnippet = (snippet) => {
          if (checkForSelected.length)
            return checkForSelected[0].view.el.innerHTML.indexOf(snippet) !== -1
              ? true
              : false;
          else return false;
        };
        //////// Old scripts
        // newEl.innerHTML = `
        // <label for="select-snippet">Select snippet:</label>
        // <select id="select-snippet">
        // <option value="" ${
        //   !checkForSelected.length ? "selected" : ""
        // }>No Snippet</option>
        // <option value="{{QS_WEBPUSH}} {{SNIPPET_2}}" ${
        //   checkForSnippet("{{SNIPPET_2}}") ? "selected" : ""
        // }>Ocamba Web Push V1</option>
        // <option value="{{QS_WEBPUSH}} <script> {{SNIPPET_45}} {{SNIPPET_50}} </script>" ${
        //   checkForSnippet("{{SNIPPET_50}}") ? "selected" : ""
        // }>Ocamba Web Push V2</option>
        // <option value="webPushOnClick" ${
        //   checkForSnippet("Hood") ? "selected" : ""
        // }>WebPush On Click</option>
        // </select>`;
        newEl.innerHTML = `
      <label for="${FieldIds.selectSnippet}">Select snippet:</label>
      <select id="${FieldIds.selectSnippet}">
        <option value=" " ${!checkForSelected.length ? "selected" : ""
          }>No Snippet</option>

        <option value="${SelectSnippetOptionValues.hoodWebpushNoSmartlinks}" 
          ${checkForSnippet("{{SNIPPET_27}}") ? "selected" : ""}
        >Hood Webpush No Smartlinks</option>

        <option value="${SelectSnippetOptionValues.hoodWebpushWithSmartlinks}"
          ${checkForSnippet("{{SNIPPET_28}}") ? "selected" : ""}
        >Hood Webpush With Smartlinks</option>

        <option value="${SelectSnippetOptionValues.loopHoodWebpush}"
          ${checkForSnippet("{{SNIPPET_25}}") ? "selected" : ""}
        >Loop Hood Webpush</option>

        <option value="${SelectSnippetOptionValues.loopHoodWebpushOnClick}"
          ${checkForSnippet("{{SNIPPET_31}}") ? "selected" : ""}
        >Loop Hood Webpush On Click</option>
      
        <option value="${SelectSnippetOptionValues.customLp}">Custom LP</option>

        <option value="${SelectSnippetOptionValues.wpMonetization}">Webpush monetization</option>
      </select>
      
      <div id="custom-lp-inputs" style="display:none;">
        <div style="margin-top: 5px">
          <label for="${FieldIds.smartLinkBlock}">Smart link block:</label>
          <input id="${FieldIds.smartLinkBlock}" />
        </div>

        <div style="margin-top: 5px">
          <label for="${FieldIds.smartLinkAllow}">Smart link allow:</label>
          <input id="${FieldIds.smartLinkAllow}" />
        </div>
      </div>`;
        return newEl;
      },
      noLabel: true,
      templateInput: `
      <h3 style="grid-column: 1/3; color: inherit;">SNIPPET SETTINGS</h3><div data-input></div>`,
      onEvent({ elInput, component, event }) {
        let snippetValue = elInput.querySelector(
          `#${FieldIds.selectSnippet}`
        ).value;
        const customLpInputsDiv = elInput.querySelector("#custom-lp-inputs");
        const smartLinkBlock = elInput.querySelector(
          `#${FieldIds.smartLinkBlock}`
        );
        const smartLinkAllow = elInput.querySelector(
          `#${FieldIds.smartLinkAllow}`
        );

        let lpScript = "";

        if (snippetValue === "customLp") {
          customLpInputsDiv.style.display = "block";
          const { id: currentEventId, value: currentEventValue } = event.target;
          let { value: smartLinkBlockValue } = smartLinkBlock;
          let { value: smartLinkAllowValue } = smartLinkAllow;

          if (currentEventId === FieldIds.smartLinkBlock) {
            smartLinkBlockValue = currentEventValue;
          } else if (currentEventId === FieldIds.smartLinkAllow) {
            smartLinkAllowValue = currentEventValue;
          }

          if (!(smartLinkBlockValue && smartLinkAllowValue)) {
            snippetValue = "";
          } else {
            lpScript = getCustomLpScript(
              smartLinkBlockValue,
              smartLinkAllowValue
            );
          }
        } else {
          customLpInputsDiv.style.display = "none";
          smartLinkBlock.value = "";
          smartLinkAllow.value = "";
        }

        if (checkForElements("snippet").length === 0) {
          editor.addComponents(
            `<div id="snippet" style="position: fixed; z-index: -1; top: 0; left:0;"></div>`
          );
        } else {
          const snippetDiv = checkForElements("snippet")[0];
          snippetDiv.set("components", lpScript || snippetValue);
        }
      },
    });
  }
  //////////////////////////////////////GRAPESJS EVENTS/////////////////////////////////////////

  editor.on("storage:load", function (e) {
    console.log("STORAGE:LOAD ");
  });
  editor.on("storage:store", function (e) {
    console.log("STORAGE:STORE ");
  });

  editor.on("asset:upload:start", () => {
    message.info("Upload started!", 2.5);
  });

  editor.on("asset:upload:end", () => {
    message.success("Upload successfull!", 2.5);
  });

  editor.on("asset:upload:error", (err) => {
    message.error(err, 2.5);
  });

  /////////////////////////////BOX POSITIONING//////////////////////////////

  // editor.TraitManager.addType("custom-box-positioning", {
  //   eventCapture: ["click"],
  //   createInput({ trait }) {
  //     const traitOpts = trait.get("options") || [];
  //     const options = traitOpts.length
  //       ? traitOpts
  //       : [
  //           { value: "left", name: "Left" },
  //           { value: "center", name: "Center" },
  //           { value: "right", name: "Right" },
  //         ];
  //     // const verticalOpts = [
  //     //   { value: "top", name: "Top" },
  //     //   { value: "middle", name: "Middle" },
  //     //   { value: "bottom", name: "Bottom" },
  //     // ];
  //     const newEl = document.createElement("div");
  //     newEl.classList.add("positioning-box-wrapper");
  //     newEl.innerHTML = `<h3 style="grid-column: 1/3;">HORIZONTAL ALLIGNMENT</h3>`;
  //     options.forEach(
  //       (option) =>
  //         (newEl.innerHTML += `<input type="radio" id='${option.value}' name="translate-position" value="${option.value}">
  //         <label for="${option.value}">${option.name}</label><br>`)
  //     );
  //     // newEl.innerHTML += `<h3 style="grid-column: 1/3;">VERTICAL ALLIGNMENT</h3>`;
  //     // verticalOpts.forEach(
  //     //   (option) =>
  //     //     (newEl.innerHTML += `<input type="radio" id='${option.value}' name="translate-vertical-position" value="${option.value}">
  //     //     <label for="${option.value}">${option.name}</label><br>`)
  //     // );
  //     return newEl;
  //   },
  //   noLabel: true,
  //   templateInput: `
  //   <div data-input></div>
  // `,
  //   onEvent({ elInput, component, event }) {
  //     let boxPositionH = null;
  //     let boxPositionV = null;
  //     if (event.target.name === "translate-vertical-position") {
  //       boxPositionV = event.target.value;
  //       component.set({ boxPositionV });
  //     } else {
  //       boxPositionH = event.target.value;
  //       component.set({ boxPositionH });
  //     }
  //   },
  // });

  //////////////////////////////ADDING GRADIENT OPTIONS TO BODY//////////////////////////////////
  editor.DomComponents.getWrapper().attributes.stylable.push(
    "Direction",
    "Type",
    "font-family",
    "font-size",
    "font-weight",
    "letter-spacing",
    "color",
    "line-height",
    "font-style",
    "text-align",
    "display",
    "flex-direction",
    "justify-content",
    "align-items",
    "align-self",
    "flex-grow",
    "flex-shrink",
    "flex-basis",
    "label-parent-flex",
    "flex",
    "order"
  );

  //////////////////////////////CUSTOM DATE INPUT TRAIT//////////////////////////////////

  editor.TraitManager.addType("date-input", {
    eventCapture: ["change"],
    createInput({ trait }) {
      const newEl = document.createElement("div");
      newEl.classList.add("dateinput-wrapper");
      let currentDate = new Date()
        .toLocaleString()
        .substring(0, 10)
        .split("/")
        .reverse();
      currentDate = `${currentDate[0]}-${currentDate[2]}-${currentDate[1]}`;
      newEl.innerHTML = `<input type="date" id="dateinput-select" name="dateinput-select" value="${currentDate}">`;
      return newEl;
    },
    onEvent({ elInput, component, event }) {
      const datePicked = event.target.valueAsNumber;
      component.set({ datePicked });
    },
  });

  //////////////////////////////EXTENDING LINK MODEL//////////////////////////////////
  let mdlLink =
    editor.DomComponents.getType("link").model.prototype.defaults.traits;
  if (mdlLink.length < 4) {
    mdlLink.push({
      type: "checkbox",
      name: "triggerWebpush",
      changeProp: 1,
      label: "Trigger WebPush on Click",
      valueTrue: true, // Value to assign when is checked, default: `true`
      valueFalse: false, // Value to assign when is unchecked, default: `false`
    });
  }

  editor.DomComponents.getType("link").view = editor.DomComponents.getType(
    "link"
  ).view.extend({
    init() {
      this.listenTo(
        this.model,
        "change:triggerWebpush",
        this.onTriggerWebPushChange
      );
    },
    onTriggerWebPushChange(model) {
      let mdl = model.view.attr;
      if (model.get("triggerWebpush")) {
        if (mdl.href) {
          mdl.href = "";
        }
        mdl.onclick = `Hood('requestPushPermission');`;
      } else {
        mdl.href = "{{FILE_URL}}";
        mdl.onclick = "";
      }
    },
  });
};
