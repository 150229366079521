import { formJS } from "./presets/form/formJS";

export const formElement = (editor, res) => {
  editor.DomComponents.addType("cod-form", {
    isComponent: (el) => {
      if (el.tagName === "DIV") {
        if (el.className && el.className.includes("cod-form")) {
          const result = {
            type: "cod-form",
          };
          return result;
        }
      }
    },
    model: {
      defaults: {
        tagName: "div",
        draggable: true,
        droppable: false,
        preset: null,
        customPosition: null,
        animation: false,
        animationDelay: 0,
        animationDuration: 1,
        traits: [
          {
            type: "select-preset",
            name: "preset",
            changeProp: 1,
            label: "More notification templates: ",
            options: res,
          },
          "title",
          "channelId",
          "productId",
          {
            name: "productName",
            label: "Product name",
          },
          {
            type: "select",
            name: "country",
            options: [
              { id: "me", value: "me", name: "ME" },
              { id: "rs", value: "rs", name: "RS" },
              { id: "ba", value: "ba", name: "BA" },
            ],
            label: "Country",
          },
        ],
      },
    },

    view: {
      tagName: "div",
      events: {
        click: "clickOnForm",
      },
      init() {
        this.listenTo(this.model, "change:preset", this.onPresetChange);
        this.listenTo(this.model, "change:attributes", this.onAttributesChange);
      },
      onPresetChange(model) {
        if (model.get("preset")) {
          const filtered = res.filter(
            (el) => el.id.toString() === model.get("preset")
          );
          if (filtered[0].content.indexOf("gj-ad-slider-content") !== -1)
            model.setAttributes({
              class: "gj-ad-slider-wrapper",
            });
          model.components(filtered[0].content);
          model.append(formJS, { at: 6 }); 
          // six hidden input fields before {{QS_WEBPUSH}} script
        }
        this.onAttributesChange(model);
      },
      onAttributesChange(model) {
        let selected_country = model.getAttributes().country;

        let url = model.find("#countryURL")[0];
        url.addAttributes({
          value: `https://bluemoon-${
            selected_country === "me"
              ? "mne-"
              : selected_country === "rs"
              ? "srb-"
              : ""
          }hub.ad-pro.info/affiliate/api/noea`,
        });

        let tnx = model.find("#tnxLocation")[0];
        tnx.addAttributes({
          value: `tnx${
            selected_country === "ba"
              ? "-ba"
              : selected_country === "rs"
              ? "-rs"
              : ""
          }`,
        });

        let selected_channel = model.find("#channelId")[0];
        let new_channelId = model
          .get("traits")
          .where({ name: "channelId" })[0]
          .get("value");
        selected_channel.addAttributes({ value: new_channelId });

        let selected_product = model.find("#productId")[0];
        let new_productId = model
          .get("traits")
          .where({ name: "productId" })[0]
          .get("value");
        selected_product.addAttributes({ value: new_productId });

        let product_name = model.find("#productName")[0];
        let new_productName = model
          .get("traits")
          .where({ name: "productName" })[0]
          .get("value");
        product_name.addAttributes({ value: new_productName });
      },
    },
  });
  editor.on("component:add", (component) => {
    let type = component.get("type");
    if (type === "cod-form") {
      if (editor.getWrapper().find(".cod-form").length > 1) {
        component.remove();
        // editor.DomComponents.getWrapper().find('.js_wrapper')[0].remove();
      } 
    }
  });
};
