export const styleManagerSettings = {
  appendTo: ".styles-container",
  sectors: [
    {
      name: "Layout",
      open: false,
      buildProps: [
        "width",
        "height",
        "max-width",
        "min-width",
        "max-height",
        "min-height",
        "margin",
        "padding",
      ],
      properties: [
        {
          property: "margin",
          properties: [
            { name: "Top", property: "margin-top" },
            { name: "Right", property: "margin-right" },
            { name: "Bottom", property: "margin-bottom" },
            { name: "Left", property: "margin-left" },
          ],
        },
        {
          property: "padding",
          properties: [
            { name: "Top", property: "padding-top" },
            { name: "Right", property: "padding-right" },
            { name: "Bottom", property: "padding-bottom" },
            { name: "Left", property: "padding-left" },
          ],
        },
      ],
    },
    {
      name: "Typography",
      open: false,
      buildProps: [
        "font-family",
        "font-size",
        "font-weight",
        "letter-spacing",
        "color",
        "line-height",
        "font-style",
      ],
      properties: [
        {
          property: "font-family",
          name: "Font",
          list: [
            { name: "Arial", value: "Arial, Helvetica, sans-serif" },
            { value: "'Oswald', sans-serif", name: "Oswald" },
            {
              value: "Helvetica Neue,Helvetica,Arial,sans-serif",
              name: "Helvetica",
            },
            { value: "sans-serif", name: "sans-serif" },
            { value: "Times New Roman", name: "Times New Roman" },
            { value: "Arial Black", name: "Arial Black" },
            { value: "Tahoma", name: "Tahoma" },
            { value: "Verdana, Geneva, sans-serif", name: "Verdana" },
            {
              value: "Courier New Courier, monospace",
              name: "Courier New Courier",
            },
            { value: "'Lato', sans-serif", name: "Lato" },
            { value: "'Open Sans', sans-serif", name: "Open Sans" },
            { value: "'Montserrat', sans-serif", name: "Montserrat" },
          ],
        },
        {
          name: "Font Style",
          property: "font-style",
          type: "radio",
          list: [
            {
              value: "normal",
              className: "fa fa-font",
              title: "Normal(Default)",
            },
            { value: "italic", className: "fa fa-italic", title: "Italic" },
          ],
        },
        {
          name: "Text align",
          property: "text-align",
          type: "radio",
          list: [
            { value: "left", className: "fa fa-align-left" },
            { value: "center", className: "fa fa-align-center" },
            { value: "right", className: "fa fa-align-right" },
            { value: "justify", className: "fa fa-align-justify" },
          ],
        },
      ],
    },
    {
      name: "Background",
      open: false,
      buildProps: [
        "background-color",
        "background-image",
        "background-repeat",
        "background-position",
        "background-attachment",
        "background-size",
      ],
      properties: [
        {
          property: "background-color",
          name: "Fill color",
        },
        {
          property: "background-image",
          name: "Background",
        },
      ],
    },
    {
      name: "Border",
      open: false,
      buildProps: ["border", "border-radius"],
      properties: [
        {
          property: "border",
          name: "Border Options",
          properties: [
            { name: "Border Color", property: "border-color" },
            { name: "Border Width", property: "border-width" },
            {
              name: "Border Style",
              property: "border-style",
              type: "select",
              options: [
                { value: "dotted", name: "Dotted" },
                { value: "dashed", name: "Dashed" },
                { value: "solid", name: "Solid" },
                { value: "double", name: "Double" },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "Shadow",
      open: false,
      buildProps: ["box-shadow", "text-shadow"],
    },

    {
      name: "Extra",
      open: false,
      buildProps: [
        "margin",
        "float",
        "display",
        "position",
        "top",
        "right",
        "left",
        "bottom",
      ],
      properties: [
        {
          name: "Margin Alignment",
          property: "margin",
          type: "radio",
          defaults: "none",
          list: [
            { value: "0", className: "fa fa-times" },
            { value: "0 auto 0 auto", className: "fa fa-align-center" },
          ],
        },
        {
          name: "Float Alignment",
          property: "float",
          type: "radio",
          defaults: "none",
          list: [
            { value: "none", className: "fa fa-times" },
            { value: "left", className: "fa fa-align-left" },
            { value: "right", className: "fa fa-align-right" },
          ],
        },
        { property: "position", type: "select" },
        {
          name: "Gradient",
          property: "background-image",
          type: "gradient",
          defaults: "none",
        },
      ],
    },
    {
      name: "Flex",
      open: false,
      properties: [
        {
          name: "Flex Container",
          property: "display",
          type: "select",
          defaults: "block",
          list: [
            { value: "block", name: "Disable" },
            { value: "flex", name: "Enable" },
          ],
        },

        {
          name: "Direction",
          property: "flex-direction",
          type: "radio",
          defaults: "row",
          list: [
            {
              value: "row",
              name: "Row",
              className: "flex-icon flex-dir-row",
              title: "Row",
            },
            {
              value: "row-reverse",
              name: "Row reverse",
              className: "flex-icon flex-dir-row-rev",
              title: "Row reverse",
            },
            {
              value: "column",
              name: "Column",
              title: "Column",
              className: "flex-icon flex-dir-col",
            },
            {
              value: "column-reverse",
              name: "Column reverse",
              title: "Column reverse",
              className: "flex-icon flex-dir-col-rev",
            },
          ],
        },
        {
          name: "Justify",
          property: "justify-content",
          type: "radio",
          defaults: "flex-start",
          list: [
            {
              value: "flex-start",
              className: "flex-icon flex-just-start",
              title: "Start",
            },
            {
              value: "flex-end",
              title: "End",
              className: "flex-icon flex-just-end",
            },
            {
              value: "space-between",
              title: "Space between",
              className: "flex-icon flex-just-sp-bet",
            },
            {
              value: "space-around",
              title: "Space around",
              className: "flex-icon flex-just-sp-ar",
            },
            {
              value: "center",
              title: "Center",
              className: "flex-icon flex-just-sp-cent",
            },
          ],
        },
        {
          name: "Align",
          property: "align-items",
          type: "radio",
          defaults: "center",
          list: [
            {
              value: "flex-start",
              title: "Start",
              className: "flex-icon flex-al-start",
            },
            {
              value: "flex-end",
              title: "End",
              className: "flex-icon flex-al-end",
            },
            {
              value: "stretch",
              title: "Stretch",
              className: "flex-icon flex-al-stretch",
            },
            {
              value: "center",
              title: "Center",
              className: "flex-icon flex-al-center",
            },
          ],
        },
        {
          name: "Flex Children",
          property: "label-parent-flex",
          type: "integer",
        },
        {
          name: "Order",
          property: "order",
          type: "integer",
          defaults: 0,
          min: 0,
        },
        {
          name: "Flex",
          property: "flex",
          type: "composite",
          properties: [
            {
              name: "Grow",
              property: "flex-grow",
              type: "integer",
              defaults: 0,
              min: 0,
            },
            {
              name: "Shrink",
              property: "flex-shrink",
              type: "integer",
              defaults: 0,
              min: 0,
            },
            {
              name: "Basis",
              property: "flex-basis",
              type: "integer",
              units: ["px", "%", ""],
              unit: "",
              defaults: "auto",
            },
          ],
        },
        {
          name: "Align Self",
          property: "align-self",
          type: "radio",
          defaults: "auto",
          list: [
            {
              value: "auto",
              name: "Auto",
            },
            {
              value: "flex-start",
              title: "Start",
              className: "flex-icon flex-al-start",
            },
            {
              value: "flex-end",
              title: "End",
              className: "flex-icon flex-al-end",
            },
            {
              value: "stretch",
              title: "Stretch",
              className: "flex-icon flex-al-stretch",
            },
            {
              value: "center",
              title: "Center",
              className: "flex-icon flex-al-center",
            },
          ],
        },
      ],
    },
  ],
};
