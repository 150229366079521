import axiosStatic from "axios";

import { API_BASE_URL } from "../config";
import { openErrorNotification } from "../utils/notifications";
import { authState } from "./auth-state";

const axiosInstance = axiosStatic.create({
  baseURL: API_BASE_URL,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
  withCredentials: true
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      authState.setLoggedIn(false); // this will also notify app component to redirect to login page
    } else if (error.response && error.response.status === 403) {
      openErrorNotification({
        message: "You do not have permission to perform this action.",
      });
    }

    // TODO: some error message if something else fails (not 401 or 403) ?
    return Promise.reject(error);
  }
);

export const callApi = ({
  url,
  method,
  data,
  cancelToken,
  headers,
  responseType,
  baseURL,
}) =>
  axiosInstance({
    url,
    method: method ?? "GET",
    data,
    cancelToken,
    headers,
    responseType,
    baseURL
  }).then((response) => response.data);

export const getCancelToken = () => axiosStatic.CancelToken.source();

export const isCancelled = (error) => axiosStatic.isCancel(error);

// if (process.env.NODE_ENV === "development") {
//   addMockApiRoutes(axiosInstance);
// }
