import React from "react";
import { Redirect, Route } from "react-router-dom";

import { authState } from "../services/auth-state";
import { getQueryString } from "../utils/query";

export const AuthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      return authState.isLoggedIn() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={`/login?${getQueryString({ next: props.location.pathname })}`}
        />
      );
    }}
  />
);

export const UnauthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      return !authState.isLoggedIn() ? (
        <Component {...props} />
      ) : (
        <Redirect to="/" />
      );
    }}
  />
);
