import React from "react";
import HomeCard from "../pages/Home/components/HomeCard/HomeCard";
import Spinner from "../components/Spinner";
import { createFromIconfontCN } from "@ant-design/icons";

export const renderCards = (arrayOfParams, setCurrentCategory) => {
  if (arrayOfParams.length > 0) {
    return arrayOfParams.map((param, id) => (
      <HomeCard
        setCurrentCategory={setCurrentCategory}
        title={param.title}
        icon={param.icon}
        description={param.description}
        key={id + 1}
      />
    ));
  } else {
    return (
      <div
        style={{
          gridColumn: "1/4",
          textAlign: "center",
          //alignSelf: "center",
        }}
      >
        {Spinner}
      </div>
    );
  }
};

export const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_2014470_6xw42np2ght.js",
});
