import { IAM_URL } from "../config";
import { EventEmitter } from "../utils/event-emitter";
import { clearCSRFCookies } from "../utils/functions";
import { callApi } from "./api";
import { authState } from "./auth-state";

export class AuthService {
  user = {
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
  };

  eventEmitter = new EventEmitter();

  async loadLoggedInState() {
    try {
      clearCSRFCookies(); // iam causes issues with csrf (multiple tokens) so this is a simple workaround
      await callApi({ baseURL: IAM_URL, url: '/api/auth/login/' }); // Call IAM so it sets CSRF token if missing
      authState.setLoggedIn(true);
    } catch {
      authState.setLoggedIn(false);
    }
  }

  registerSubscriber(subscriber) {
    this.eventEmitter.registerSubscriber(subscriber);
  }

  unregisterSubscriber(subscriber) {
    this.eventEmitter.unregisterSubscriber(subscriber);
  }
}

export const authService = new AuthService();
